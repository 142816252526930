import { useState, useContext } from 'react';

import { UserModalContext } from '@/context/user-modal.context';

import { checkEmail } from '@/utils/functions/validation.functions';

import CloseIcon from '@material-ui/icons/Close';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const UserForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [authMessage, setAuthMessage] = useState('');
  const { setShowUserModal, setUserModalType } = useContext(UserModalContext);

  const sendEmail = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    };

    fetch('/api/v1/user/forgotPassword', requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (!data.success) {
          setIsUpdating(400);
          setTimeout(() => setIsUpdating(false), 3000);
          if (data.error === 404) setAuthMessage('error-no-user');
          else setAuthMessage('error');
        } else {
          setIsUpdating(200);
          setTimeout(() => {
            setIsUpdating(false);
            setShowUserModal(true);
            setUserModalType('signIn');
          }, 3000);
          setAuthMessage('ok');
        }
      })
      .catch(console.log)
      .then(setTimeout(() => setIsUpdating(false), 3000));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsUpdating(true);
    sendEmail();
  };

  return (
    <form className="modal-box password-box">
      <CloseIcon fontSize="medium" className="modal-close-x" />
      <div className="title">Zaboravili ste lozinku?</div>
      <div className="inputs">
        <div className="form-control">
          <input
            type="text"
            name="email"
            id="email"
            autoFocus
            placeholder="Email adresa"
            onChange={(e) => setEmail(e.target.value)}
            className={authMessage.includes('error') ? 'validation-error' : null}
          />
          <small>
            {authMessage.length > 0
              ? authMessage === 'error'
                ? 'Greška. Molimo pokušajte ponovo.'
                : authMessage === 'error-no-user'
                ? 'Korisnik s tom adresom ne postoji.'
                : authMessage === 'ok'
                ? 'Email će biti poslan na danu adresu.'
                : null
              : null}
          </small>
        </div>
      </div>
      <div className="buttons">
        <button
          type="submit"
          disabled={!checkEmail(email) || isUpdating}
          className="button button-lokalac"
          onClick={handleSubmit}
        >
          {isUpdating ? (
            <AvTimerIcon />
          ) : isUpdating === 200 ? (
            <DoneAllIcon />
          ) : isUpdating === 400 ? (
            <ErrorOutlineIcon />
          ) : (
            'Pošalji upute'
          )}
        </button>
      </div>
    </form>
  );
};

export default UserForgotPassword;
