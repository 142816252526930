import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid var(--lokalac-primary-1);

  .title {
    font-size: var(--lokalac-l);

    span {
      font-weight: 500;
    }
  }

  .select-all-locations {
    display: flex;
    align-items: center;
    padding: 20px 0;
  }

  .primary-option {
    cursor: pointer;
  }

  .locations {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 10px;
  }
`;
