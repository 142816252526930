import { useEffect, useContext } from 'react';

import { FeedsContext } from '@/context/feeds.context';

import { Container } from './personalize-feed.elements';

import { findCommonElements } from '@/utils/functions/helper.functions';

const PersonalizeFeed = ({
  county,
  countiesAndFeeds,
  newFeeds,
  setNewFeeds,
  newCounties,
  setNewCounties,
}) => {
  const { feeds } = useContext(FeedsContext);

  useEffect(() => {
    // Select counties
    if (
      findCommonElements(newFeeds, feeds.countiesAndFeeds[county].feeds) ===
      feeds.countiesAndFeeds[county].feeds.length
    ) {
      const index = newCounties.indexOf(county);
      if (index === -1) {
        setNewCounties((prev) => [...prev, county]);
      }
    } else if (
      findCommonElements(newFeeds, feeds.countiesAndFeeds[county].feeds) <
      feeds.countiesAndFeeds[county].feeds.length
    ) {
      const index = newCounties.indexOf(county);
      if (index !== -1) {
        const countyArray = [...newCounties];
        countyArray.splice(index, 1);
        setNewCounties(countyArray);
      }
    }
    //...
  }, [newFeeds]);

  const handleChange = (e) => {
    const index = newFeeds.indexOf(e.target.id);

    if (index === -1) {
      setNewFeeds((prev) => [...prev, e.target.id]);
    } else {
      const array = [...newFeeds];
      array.splice(index, 1);
      setNewFeeds(array);
    }
  };

  return (
    <Container>
      <div className="county">{county}</div>
      <div className="feeds-list">
        {countiesAndFeeds[county].feeds.map((feed, j) => (
          <div className="form-control" key={j}>
            <input
              type="checkbox"
              name="feed"
              id={feed}
              checked={newFeeds.includes(feed)}
              onChange={handleChange}
            />
            <div id={feed} onClick={handleChange}>
              {feed}
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default PersonalizeFeed;
