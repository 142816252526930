import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 0 20px;
  width: 240px;
  user-select: none;

  .county {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-right: 10px;
    }

    input {
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .options {
    margin: 5px 0 0 30px;

    .option-1 {
      display: flex;
      font-size: var(--lokalac-xs);
      margin-bottom: 5px;

      input {
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .option-2 {
      display: flex;
      flex-direction: column;
      font-size: var(--lokalac-xs);

      .option {
        display: flex;
        cursor: pointer;

        i {
          font-size: 18px;
          margin: 0 10px 0 6px;
        }
      }

      .cities {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-left: 27px;
        margin-top: 5px;
        padding: 10px;
        border-radius: 10px;
        background: var(--lokalac-primary-4);
        color: var(--lokalac-primary-2);
        font-weight: 500;

        .city {
          display: flex;
          align-items: center;

          input {
            margin-right: 10px;
            cursor: pointer;
          }

          span {
            cursor: pointer;
          }
        }
      }
    }
  }
`;
