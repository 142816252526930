import Image from 'next/image';
import Link from 'next/link';

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailIcon from '@material-ui/icons/Mail';

import { Container, LogoWrap } from './card-footer.elements';

const CardFooter = ({ sourceName, articleUrl, title }) => {
  return (
    <Container>
      <LogoWrap>
        <Link href={'/portal/' + sourceName}>
          <Image
            src={`https://${process.env.NEXT_PUBLIC_S3_BUCKET}.${process.env.NEXT_PUBLIC_S3_URL}/${
              process.env.NEXT_PUBLIC_S3_FOLDER
            }/logo/${sourceName.replace('/', '')}.png`}
            alt="Logo"
            width={110}
            height={18}
            style={{ objectFit: 'contain', maxWidth: '100%' }}
          />
        </Link>
      </LogoWrap>

      <div className="social">
        <div className="text">podijeli:</div>
        <div className="icons">
          <div className="icon">
            <EmailShareButton url={articleUrl} subject={title}>
              <MailIcon fontSize="small" />
            </EmailShareButton>
          </div>
          <div className="icon">
            <FacebookShareButton url={articleUrl} quote={title}>
              <FacebookIcon fontSize="small" />
            </FacebookShareButton>
          </div>
          <div className="icon">
            <TwitterShareButton url={articleUrl} title={title}>
              <TwitterIcon fontSize="small" />
            </TwitterShareButton>
          </div>
          <div className="icon">
            <WhatsappShareButton url={articleUrl} title={title}>
              <WhatsAppIcon fontSize="small" />
            </WhatsappShareButton>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CardFooter;
