const FAQ = () => (
  <div>
    <h1>Uvjeti Korištenja</h1>
    <p>Nekakav tekst</p>
    <h2>Manji naslov</h2>
    <p>Jos teksta</p>
  </div>
);

export default FAQ;
