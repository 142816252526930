import { useContext } from 'react';
import { signIn } from 'next-auth/react';

import { UserModalContext } from '@/context/user-modal.context';

import CloseIcon from '@material-ui/icons/Close';

const UserPrompt = () => {
  const { setUserModalType } = useContext(UserModalContext);

  return (
    <div className="modal-box register-prompt-box">
      <CloseIcon fontSize="medium" className="modal-close-x" />
      <div className="title">Želite li zadržati svoj odabir portala?</div>
      <div className="buttons">
        <div className="button button-lokalac" onClick={() => setUserModalType('signUp')}>
          Registriraj se
        </div>
        <div className="or">
          <div className="line"></div>
          <div>ili</div>
          <div className="line"></div>
        </div>

        <div onClick={() => signIn('google')} className="button button-google">
          <div>Registriraj se Gmailom</div>
        </div>
        <div onClick={() => signIn('facebook')} className="button button-facebook">
          <div>Registriraj se Facebookom</div>
        </div>
        <div className="wave-emoji">👋</div>
        <div className="existing" onClick={() => setUserModalType('signIn')}>
          Već imaš račun? Prijavi se ovdje.
        </div>
      </div>
    </div>
  );
};

export default UserPrompt;
