import { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';

import { useCookies } from 'react-cookie';
import debounce from 'lodash.debounce';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { FeedsContext } from '@/context/feeds.context';
import { UserModalContext } from '@/context/user-modal.context';

import { LocationFilterItem } from '@/components';

import {
  Container,
  LocationsContainer,
  LocationList,
  LocationListItem,
  Buttons,
  Button,
  CloseMe,
} from './location-filter.elements';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import useDeviceSize from '@/hooks/use-device-size.hook';

const LocationFilter = ({ setShowLocationFilter, setView }) => {
  const [allSelected, setAllSelected] = useState(false);
  const { feeds } = useContext(FeedsContext);
  const { setShowUserModal, setUserModalType } = useContext(UserModalContext);
  const [cookies, setCookie] = useCookies(['feeds']);
  const [width, height] = useDeviceSize();
  const router = useRouter();

  useEffect(() => {
    if (feeds?.feeds && cookies?.feeds) setAllSelected(cookies.feeds.length === feeds.feeds.length);
  }, [cookies.feeds, feeds]);

  const handleSelectAllFeeds = debounce(() => {
    if (cookies?.feeds?.length === feeds?.feeds?.length) {
      setAllSelected(false);
      setCookie('feeds', [], { path: '/' });
    } else {
      setAllSelected(true);
      setCookie('feeds', feeds.feeds, { path: '/' });
    }

    if (router.pathname.includes('portal')) router.push('/');
  }, 250);

  const closeLocationsOpenCategories = () => {
    setShowLocationFilter(false);
    setTimeout(() => setView('categories'), 600);
  };

  const halfLen = Math.ceil(feeds?.counties?.length / 2);
  const leftSide = feeds?.counties?.slice(0, halfLen);
  const rightSide = feeds?.counties?.slice(halfLen);

  return width ? (
    <Container>
      <LocationsContainer>
        {width >= 768 ? (
          <LocationList>
            {feeds?.counties?.map((item, i) => (
              <LocationListItem key={i}>
                <LocationFilterItem
                  county={feeds.countiesAndCities[item].name}
                  cities={feeds.countiesAndCities[item].cities}
                />
              </LocationListItem>
            ))}
          </LocationList>
        ) : (
          <Carousel showStatus={false} showIndicators={false}>
            <LocationList>
              {leftSide?.map((item, i) => (
                <LocationListItem key={i}>
                  <LocationFilterItem
                    county={feeds.countiesAndCities[item].name}
                    cities={feeds.countiesAndCities[item].cities}
                  />
                </LocationListItem>
              ))}
            </LocationList>
            <LocationList>
              {rightSide?.map((item, i) => (
                <LocationListItem key={i}>
                  <LocationFilterItem
                    county={feeds.countiesAndCities[item].name}
                    cities={feeds.countiesAndCities[item].cities}
                  />
                </LocationListItem>
              ))}
            </LocationList>
          </Carousel>
        )}
      </LocationsContainer>
      <Buttons>
        <Button onClick={handleSelectAllFeeds}>{allSelected ? 'Odznači sve' : 'Označi sve'}</Button>
        <Button
          onClick={() => {
            setUserModalType('prompt');
            setShowUserModal(true);
          }}
        >
          Spremi
        </Button>
        <CloseMe onClick={closeLocationsOpenCategories}>
          <span>zatvori</span> <ExpandLessIcon fontSize="small" />
        </CloseMe>
      </Buttons>
    </Container>
  ) : null;
};

export default LocationFilter;
