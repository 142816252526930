import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid var(--lokalac-primary-1);

  .title {
    font-size: var(--lokalac-l);

    span {
      font-weight: 500;
    }
  }

  .select-all-row {
    display: flex;
    align-items: center;
    padding: 20px 0;

    .select-all-feeds {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .warning {
      padding: 8px 28px;
      border-radius: 15px;
      margin-left: -20px;
      background: #000;
      z-index: 5;
      font-size: var(--lokalac-xs);

      span {
        &:first-child {
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 1px;
          margin-right: 15px;
        }
      }
    }
  }

  .message-box {
    margin-top: 20px;
  }

  .feeds {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px 0;
  }

  @media only screen and (max-width: 1200px) {
    .select-all-row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .warning {
        margin-left: 0;
        margin-top: 8px;
        padding: 8px 28px;
      }
    }
  }
`;
