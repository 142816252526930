import { Container } from './cookies-policy.elements';

const CookiesPolicy = () => (
  <Container>
    <h1>POLITIKA KOLAČIĆA</h1>
    <h3>POLITIKA O COOKIEJIMA</h3>
    <p>
      Lokalac.hr koristi tehnologiju takozvanih internetskih cookieja (hrvatski: kolačića) te se s
      pravilima kojima reguliramo privatnost i korištenje cookieja, u skladu s novom europskom
      regulativom, možete upoznati ovim putem. Ukoliko želite onemogućiti korištenje cookieja, upute
      se nalaze na dnu ove stranice.
    </p>

    <h3>ZAŠTO KORISTIMO COOKIEJE (HRVATSKI: KOLAČIĆE)?</h3>
    <p>
      Tehnologiju cookieja koristimo kako bismo mogli pružati našu online uslugu, analizirati
      korištenje sadržaja, nuditi oglašivačka rješenja, kao i za ostale funkcionalnosti koje ne
      bismo mogli pružati bez korištenja cookieja. Detaljan popis cookieja potražite u nastavku ovog
      teksta. Podaci pohranjeni korištenjem cookieja na portalu Lokalac.hr ne sadrže osobne detalje
      iz kojih bi se mogao utvrditi identitet korisnika.
    </p>

    <h3>ŠTO JE COOKIE I ČEMU SLUŽI?</h3>
    <p>
      Najjednostavnije rečeno, cookie je zapis koju web stranice pohranjuju i / ili dobivaju iz
      memorije vašeg uređaja, kroz preglednik koji koristite. Ukoliko ste, na primjer, na nekoj web
      stranici odabrali hrvatski jezik kao željeni jezik komunikacije, podatak o vašem odabiru bit
      će pohranjen u obliku cookieja. Većina cookieja ima vremenski rok trajanja, a svaki se može
      obrisati ili onemogućiti u postavkama vašeg preglednika.
    </p>
    <p>
      Radi pamćenja vaših postavki i navika, korištenje cookieja pomaže u brzini i sigurnosti
      pristupanja stranicama, a u mnogim slučajevima nužni su za normalno prikazivanje sadržaja i
      funkcioniranje stranice. Takozvani first-party cookieji (kolačići prve strane) sadrže podatke
      stranice kojoj pristupate, a third-party cookieji (kolačići treće strane) sadrže podatke
      trećih servisa, na primjer, alati društvenih mreža na člancima, za lakše dijeljenje članaka.
    </p>

    <h3>KAKVE COOKIEJE KORISTIMO?</h3>
    <p>
      Lokalac.hr cookie tehnologiju koristi odgovorno, u skladu sa važećom nacionalnom i europskom
      regulativom, isključivo za unaprjeđenje vašeg korisničkog iskustva i omogućavanje pružanja
      naših usluga. Cookieje koji se koriste na našim izdanjima uglavnom spadaju u tri osnovne
      kategorije:
    </p>

    <h4>Strogo potrebni cookieji</h4>
    <p>
      Cookieji bez kojih rad na portala Lokalac.hr ne bi bio moguć. Rok trajanja cookieja iznosi tri
      godine.
    </p>
    <h4>Funkcionalni cookieji</h4>
    <p>
      Cookieji koji nam omogućavaju da pamtimo vaše postavke i pružamo bolju uslugu. Ovi cookieji
      prikupljaju anonimne podatke i ne mogu pratiti vaše posjete drugim internetskim stranicama.
      Rok trajanja cookieja iznosi tri godine.
    </p>
    <h4>Ostali cookieji</h4>
    <p>
      Ostali cookieji su na primjer cookieji društvenih mreža poput Facebooka ili Twittera. Ovim
      cookiejima ne upravlja portal Lokalac.hr.
    </p>

    <h2>POPIS COOKIESA KOJE KORISTIMO</h2>
    <ul>
      <li>
        <strong>Google:</strong> Google DoubleClick za napredno oglašavanje
      </li>
      <li>
        <strong>Gemius:</strong> Gemius Audience istraživanje posjećenosti
      </li>
      <li>
        <strong>Gemius:</strong> Gemius Prism interna analitika posjećenosti
      </li>
      <li>
        <strong>Ipsos:</strong> Dotmetrics istraživanje posjećenosti
      </li>
      <li>
        <strong>Google:</strong> Google analitika interna analitika posjećenosti
      </li>
      <li>
        <strong>Facebook:</strong> Facebook pixel interna analitika posjećenosti
      </li>
      <li>
        <strong>Midas:</strong> Midas Network razmjena prometa i napredno oglašavanje
      </li>
      <li>
        <strong>Facebook:</strong> Facebook sadržaj i analitika
      </li>
      <li>
        <strong>Google:</strong> Youtube sadržaj i analitika
      </li>
      <li>
        <strong>Twitter:</strong> Twitter sadržaj i analitika
      </li>
      <li>
        <strong>Facebook:</strong> Instagram sadržaj i analitika
      </li>
    </ul>

    <p>
      Marketinški cookieji poput Google Doubleclicka bilježe posjete našim internetskim stranicama.
      Ti podaci koriste se kako bismo ograničili broj prikazivanja oglasa, unaprijedili i
      optimizirali učinkovitost marketinških kampanja, te dostavljali oglase koji su u skladu s vama
      i vašim interesima. Ove podatke možemo podijeliti s trećim osobama poput oglašivača. Rok
      trajanja ovih kolačića može biti više od godine dana ili do uklanjanja kolačića u internetskom
      pregledniku.
    </p>
    <p>
      Analitički cookieji poput Gemius Audiencea i Google Analyticsa omogućavaju portalu Lokalac.hr
      prepoznavanje i prebrojavanje korisnika, kao i prikupljanje podataka o tome kako se stranice
      koriste – na primjer, koje stranice korisnici najčešće otvaraju i prima li korisnik obavijesti
      o greškama od pojedinih stranica). To nam pomaže unaprijediti rad internetskih stranica,
      primjerice, da vam osiguramo lako pronalaženje svega što tražite. Trajanje ovih cookiesa može
      biti više od godine dana ili do uklanjanja u postavkama.
    </p>
    <p>
      Napomena: Ukoliko naša izdanja počnu koristiti dodatne cookieje koji trenutno nisu navedeni, o
      tome ćemo vas obavijestiti kroz ažuriranje ove stranice.
    </p>

    <h3>KAKO MOGU OBRISATI ILI BLOKIRATI COOKIESE?</h3>
    <p>
      Suglasnost za cookieje možete povući kada god želite. Cookieje možete obrisati ili blokirati
      kroz svoj internetski preglednik ili koristeći specijalizirane softvere za to. Napominjemo
      kako onemogućavanje svih cookieja može uzrokovati probleme u funkcioniranju većine stranica,
      pa tako i Lokalac.hr. Upute za postavke cookieja u vašem pregledniku možete pronaći na
      sljedećim adresama:
    </p>
    <p>
      <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=hr">
        Google Chrome
      </a>
    </p>
    <p>
      <a href="https://support.mozilla.org/hr/kb/Kola%C4%8Di%C4%87i">Mozilla Firefox</a>
    </p>
    <p>
      <a href="https://support.microsoft.com/hr-hr/help/17442/windows-internet-explorer-delete-manage-cookies">
        Internet Explorer
      </a>
    </p>
    <p>
      <a href="https://support.apple.com/hr-hr/guide/safari/sfri11471/mac">Safari</a>
    </p>
    <p>
      Ukoliko imate dodatnih pitanja o pravilima privatnosti i korištenju cookiesa, obratite nam se
      na <a href="mailto:privatnost@lokalac.hr">privatnost@lokalac.hr</a>.
    </p>
  </Container>
);

export default CookiesPolicy;
