import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  background: var(--lokalac-primary-2);
  color: var(--lokalac-text-light);

  .personalize-container {
    margin: 0 auto;
    width: 80vw;

    .title-box {
      padding: 30px 0;
      border-bottom: 1px solid var(--lokalac-primary-1);

      .title {
        font-size: var(--lokalac-xxl);

        span {
          font-weight: 500;
        }
      }
    }

    .primary-option {
      display: flex;
      width: 180px;
      font-weight: 500;
      align-items: center;
      color: var(--lokalac-primary-2);
      background: var(--lokalac-primary-3);
      z-index: 6;

      user-select: none;
      padding: 6px 12px;
      border-radius: 15px;

      input {
        margin-right: 10px;
        cursor: pointer;
      }
    }

    .save-box {
      padding: 30px 0 60px;

      .save-button {
        display: flex;
        width: 160px;
        height: 35px;
        justify-content: center;
        align-items: center;
        color: var(--lokalac-black);
        background: var(--lokalac-gold);
        border: none;
        font-weight: 700;

        user-select: none;
        padding: 8px 16px;
        border-radius: 15px;
        transition: all 0.2s ease-in-out;

        &:hover {
          cursor: pointer;
          transform: scale(1.05);
        }
      }
    }

    .footer-box {
      padding: 0 0 30px;
      width: 300px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .personalize {
      .personalize-container {
        width: 90vw;
      }
    }
  }
  @media only screen and (max-width: 650px) {
  }
  @media only screen and (max-width: 425px) {
  }
`;
