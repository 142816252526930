import { useContext } from 'react';
import Link from 'next/link';

import { UserModalContext } from '@/context/user-modal.context';
import { UserContext } from '@/context/user.context';

import { Footer } from '@/components';

import { Container, SignInButton, PersonalizeButton } from './info-box.elements';

const InfoBox = () => {
  const { setShowUserModal, setUserModalType } = useContext(UserModalContext);
  const { user } = useContext(UserContext);

  return (
    <Container>
      {!user?.isAuthenticated ? (
        <div className="content">
          <div className="welcome">Dobrodošli na Lokalac.hr</div>
          <div className="register-message">
            Pratite jednostavne korake kojima ćemo vas provesti kroz postupak personalizacije
          </div>
          <PersonalizeButton>
            <Link href="/personaliziraj" className="personalize-button">
              Personaliziraj
            </Link>
          </PersonalizeButton>
          <div className="registered-question">Imate već registrirani profil?</div>
          <SignInButton
            onClick={() => {
              setShowUserModal(true);
              setUserModalType('signIn');
            }}
          >
            Prijavi se
          </SignInButton>
        </div>
      ) : (
        <div className="content registered">
          <div className="welcomeRegistered">Dobrodošli,</div>
          <div className="welcomeName">{user.email}</div>
        </div>
      )}
      <Footer />
    </Container>
  );
};

export default InfoBox;
