import { useState, useEffect } from 'react';
import Image from 'next/image';
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { Container } from './personalize-map.elements';

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Map = ReactMapboxGl({
  accessToken: process.env.NEXT_PUBLIC_MAPBOX_KEY,
});

const PersonalizeMap = ({ data }) => {
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (data && data.feedsAndLocation) {
      const array = [];
      let prevMarker = [];
      let dupCount = 0;

      Object.keys(data.feedsAndLocation).forEach((key) => {
        array.push({
          name: data.feedsAndLocation[key].name,
          location: data.feedsAndLocation[key].location.split(','),
        });
      });

      array.sort((a, b) => (a.location > b.location ? 1 : b.location > a.location ? -1 : 0));

      array.forEach((marker, i) => {
        if (marker.location[0] === prevMarker[0] && marker.location[1] === prevMarker[1]) {
          dupCount++;
          array[i].count = dupCount;
        } else {
          dupCount = 0;
          array[i].count = dupCount;
        }
        prevMarker = marker.location;
      });

      setMarkers(array);
    }
  }, [data]);

  return (
    <Container>
      <Map
        style="mapbox://styles/mapbox/light-v10"
        center={[16, 44.55]}
        zoom={[5.9]}
        containerStyle={{
          height: '100%',
          width: '100%',
        }}
      >
        {markers.map((marker, i) => (
          <Marker coordinates={marker.location} anchor="bottom" className="marker" key={i}>
            <Image
              src={`https://${process.env.NEXT_PUBLIC_S3_BUCKET}.${
                process.env.NEXT_PUBLIC_S3_URL
              }/${process.env.NEXT_PUBLIC_S3_FOLDER}/logo/${marker.name.replace('/', '')}.png`}
              style={{
                transform: `translate(0, ${marker.count * 25}px)`,
                maxWidth: '100%',
                objectFit: 'contain',
              }}
              alt={`${marker.name} logo`}
              height={20}
              width={500}
            />
          </Marker>
        ))}
      </Map>
    </Container>
  );
};

export default PersonalizeMap;
