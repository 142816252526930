import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 20px 0 30px;
  user-select: none;

  .county {
    font-size: var(--lokalac-xs);
    color: var(--lokalac-black);
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .feeds-list {
    .form-control {
      display: flex;
      align-items: center;
      padding: 2px 0;

      input {
        margin-right: 10px;
        cursor: pointer;
      }

      div {
        cursor: pointer;
      }
    }
  }
`;
