const camelize = (str) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '');

const camelCaseToNormal = (str) => str.replace(/([A-Z])/g, ' $1').toLowerCase();

const slugify = (val) => (val ? val.replace(/ /g, '-').toLowerCase() : null);

const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

const findCommonElements = (arr1, arr2) => arr1.filter((value) => arr2.includes(value)).length;

export { camelize, camelCaseToNormal, scrollTop, slugify, findCommonElements };
