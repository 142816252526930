import Link from 'next/link';

import { Header } from '@/components';

import { Container } from './404.elements';

const FourOhFour = () => (
  <>
    <Header />
    <Container>
      <div className="title">404</div>
      <div className="subtitle">Ova stranica ne postoji ili je nedostupna.</div>
      <div className="go-back">
        <Link href="/">Idi na početnu stranicu</Link>
      </div>
    </Container>
  </>
);

export default FourOhFour;
