import Link from 'next/link';

import { Header } from '@/components';

import { Container } from './500.elements';

const FiveHundred = () => (
  <>
    <Header />
    <Container>
      <div className="title">500</div>
      <div className="subtitle">Žao nam je. Došlo je do greške.</div>
      <div className="go-back">
        <Link href="/">Idi na početnu stranicu</Link>
      </div>
    </Container>
  </>
);

export default FiveHundred;
