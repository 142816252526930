import { useState, useContext } from 'react';
import { useRouter } from 'next/router';

import { useCookies } from 'react-cookie';
import debounce from 'lodash.debounce';

import { FeedsContext } from '@/context/feeds.context';

import { CollapsibleWrapper } from '@/components';

import { Container } from './location-filter-item.elements';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { findCommonElements } from '@/utils/functions/helper.functions';

const LocationFilterItem = ({ county, cities }) => {
  const [collapsed, setCollapsed] = useState(true);
  const { feeds } = useContext(FeedsContext);
  const [cookies, setCookie] = useCookies(['feeds']);
  const router = useRouter();

  const handleCollapse = () => setCollapsed(!collapsed);

  const addOrRemoveFeeds = debounce((set, target) => {
    const includedFeeds = cookies.feeds.filter((feed) =>
      feeds[`${set}AndFeeds`][target].feeds.includes(feed)
    );

    const newFeeds =
      includedFeeds.length > 0
        ? cookies.feeds.filter((feed) => !feeds[`${set}AndFeeds`][target].feeds.includes(feed))
        : cookies.feeds.concat(feeds[`${set}AndFeeds`][target].feeds);

    setCookie('feeds', newFeeds, { path: '/' });
    if (router.pathname.includes('portal')) router.push('/');
  }, 250);

  return (
    <Container>
      <div className="county">
        <input
          type="checkbox"
          name={county}
          checked={findCommonElements(cookies.feeds, feeds.countiesAndFeeds[county].feeds) > 0}
          onChange={() => addOrRemoveFeeds('counties', county)}
        />
        <div className="name-container" onClick={handleCollapse}>
          <div className="name">{county}</div>
          {collapsed ? <ExpandMoreIcon fontSize="medium" /> : <ExpandLessIcon fontSize="medium" />}
        </div>
      </div>
      <CollapsibleWrapper open={!collapsed}>
        <div className="cities">
          {cities.map((city, i) => (
            <div className="city" key={i}>
              <input
                type="checkbox"
                name={city}
                checked={findCommonElements(cookies.feeds, feeds.citiesAndFeeds[city].feeds) > 0}
                onChange={() => addOrRemoveFeeds('cities', city)}
              />
              <div className="city-name" onClick={() => addOrRemoveFeeds('cities', city)}>
                {city}
              </div>
            </div>
          ))}
        </div>
      </CollapsibleWrapper>
    </Container>
  );
};

export default LocationFilterItem;
