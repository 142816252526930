import { useState, useEffect } from 'react';
import Link from 'next/link';

import { useCookies } from 'react-cookie';

import { Container } from './cookies-consent.elements';

import ThumbUpIcon from '@material-ui/icons/ThumbUp';

const CookiesConsent = () => {
  const [render, setRender] = useState(false);
  const [cookies, setCookie] = useCookies(['isAgreedtoCookies']);
  const expires = new Date();
  expires.setDate(expires.getDate() + 30);

  const cookieGone = () => setCookie('isAgreedtoCookies', true, { path: '/', expires });

  useEffect(() => {
    setTimeout(() => setRender(true), 5000);
  }, []);

  return render && cookies?.isAgreedtoCookies !== true ? (
    <Container>
      <div className="message">
        Lokalac koristi kolačiće.
        <div className="link" onClick={cookieGone}>
          <Link href="/politika-kolacica" target="_blank">
            Više
          </Link>
        </div>
      </div>
      <div className="remove-me" onClick={cookieGone}>
        <ThumbUpIcon fontSize="small" />
      </div>
    </Container>
  ) : null;
};

export default CookiesConsent;
