import styled from 'styled-components';

export const Container = styled.div`
  .card {
    display: flex;
    flex-direction: column;
    position: relative;

    margin-top: var(--lokalac-item-gap);
    margin-left: calc(var(--lokalac-item-gap) / 2);
    margin-right: calc(var(--lokalac-item-gap) / 2);
    transition: all 0.3s linear;

    box-shadow: 0 0 10px 4px #d0d0d0;

    .image {
      display: flex;
      justify-content: center;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      width: 100%;
      max-height: 200px;
      height: 200px;
      background-color: var(--lokalac-gray-1);
      transition: all 0.3s linear;

      img {
        width: 100%;
        max-height: 200px;
        height: 200px;
        object-fit: cover;
        transition: all 0.3s linear;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      flex: 1;
      background: var(--lokalac-background);
      transition: all 0.3s linear;
      height: 200px;
      border: 1px solid var(--lokalac-gray-1);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

      .meta {
        display: flex;
        align-items: center;
        font-size: var(--lokalac-xxs);
        margin-top: 5px;
        padding: 0 10px;

        .category {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--lokalac-text-light);
          user-select: none;

          padding: 3px 10px;
          border-radius: 15px;
          text-transform: uppercase;
          font-weight: 500;
          max-width: 150px;

          &:hover {
            cursor: pointer;
          }
        }

        .divider {
          margin: 0 5px;
        }

        .time {
          color: var(--lokalac-gray-2);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        position: relative;
        height: 100%;

        .title {
          margin-top: 10px;
          font-weight: 500;
          font-size: var(--lokalac-xl);
          max-height: 138px;

          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        a {
          color: var(--lokalac-text-dark);
        }

        .body {
          margin-top: 9px;
          font-size: var(--lokalac-xs);

          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 60px;
          transition: all 0.3s linear;
        }

        .body-empty {
          margin: 15px 0;
        }

        .read-more {
          display: flex;
          align-items: center;
          position: absolute;
          bottom: 0;
          left: 10px;
          width: calc(100% - 20px);
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          padding: 7px;
          font-size: var(--lokalac-xs);
          font-weight: 500;
          color: var(--lokalac-primary-1);
          background: var(--lokalac-primary-3);
          z-index: 3;
          opacity: 0;
          visibility: hidden;

          transition: all 1.2s ease-in-out;

          span {
            margin-right: 7px;
          }
        }
      }
    }
  }

  .card-focused-normal {
    transform: scale(1.05);
    position: relative;
    z-index: 17;

    & .image {
      height: 100px;
      img {
        height: 100px;
      }
    }
  }

  .card-focused-large {
    transform: scale(1.05);
    position: relative;
    z-index: 17;
  }

  .card-normal {
    width: var(--lokalac-card-width-normal);
    height: var(--lokalac-card-height-normal);
    transition: all 0.3s linear;

    &:hover {
      transform: scale(1.05);
      position: relative;
      z-index: 17;

      & .image {
        height: 100px;

        &:hover {
          height: 200px;

          > img {
            height: 200px;
          }
        }

        img {
          height: 100px;
        }
      }

      & .container {
        height: 360px;

        & .content {
          & .body {
            -webkit-line-clamp: 10;
            height: 180px;
          }

          & a .read-more {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  .card-small {
    width: var(--lokalac-card-width-normal);
    height: var(--lokalac-card-height-small);
  }

  .card-large {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: var(--lokalac-card-width-large);
    width: var(--lokalac-card-width-large);
    height: var(--lokalac-card-height-normal);
    margin-top: var(--lokalac-item-gap);
    margin-left: calc(var(--lokalac-item-gap) / 2);
    margin-right: calc(var(--lokalac-item-gap) / 2);
    color: var(--lokalac-text-light);
    border: 1px solid var(--lokalac-gray-1);
    transition: all 0.3s linear;

    grid-column: auto / span 2;

    .image {
      img {
        width: 100%;
        height: calc(100% - 30px);
        z-index: -2;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }

      .gradient {
        &::after {
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          background-image: linear-gradient(to bottom, transparent 0%, #000);
          opacity: 0.8;
          z-index: -1;
          width: 100%;
          height: calc(50% - 30px);

          content: '';
        }
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      margin-top: auto;

      .meta {
        display: flex;
        align-items: center;
        font-size: var(--lokalac-xxs);
        padding: 0 10px;

        .category {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--lokalac-text-light);
          user-select: none;

          padding: 3px 10px;
          border-radius: 15px;
          text-transform: uppercase;
          font-weight: 500;
          max-width: 150px;

          &:hover {
            cursor: pointer;
          }
        }

        .divider {
          margin: 0 5px;
        }

        .time {
          color: var(--lokalac-text-light);
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        padding: 0 10px;

        .title {
          font-size: var(--lokalac-xxl);
          font-weight: 500;
          margin-top: 10px;
        }

        .body {
          max-height: inherit;
          margin: 20px 0 35px;
          font-size: var(--lokalac-xs);

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .body-empty {
          margin: 15px 0;
        }

        .read-more {
          display: none;
          position: absolute;
          bottom: 25px;
          left: 0;
          width: 100%;
          justify-content: center;
          align-items: center;
          text-transform: uppercase;
          padding: 7px;
          margin: 5px 0;
          font-size: var(--lokalac-xs);
          font-weight: 500;
          color: var(--lokalac-primary-1);
          background: var(--lokalac-primary-3);

          i {
            margin-left: 7px;
          }
        }
      }
    }

    &:hover {
      transform: scale(1.05);
      position: relative;
      z-index: 17;

      & .container {
        & .content {
          & a .read-more {
            display: flex;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .card-large {
      width: var(--lokalac-card-width-normal);

      .container {
        .content {
          .title {
            margin: 10px 0 30px;
          }
          .body {
            display: none;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 650px) {
    .card-normal,
    .card-large {
      margin: 10px auto;
      width: 95%;
      margin-top: calc(var(--lokalac-item-gap));
      margin-bottom: calc(var(--lokalac-item-gap));
    }

    .card-large {
      min-width: var(--lokalac-card-width-normal);
    }

    .card {
      .container {
        .content {
          .body {
            font-size: var(--lokalac-s);
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 68px;
            transition: all 0.3s linear;
          }
        }
      }

      &:hover {
        transform: none;
      }
    }

    .card-focused-normal {
      transform: scale(1.05);

      z-index: 17;
      /* margin-top: calc(var(--lokalac-item-gap) * 2);
      margin-bottom: calc(var(--lokalac-item-gap) * 2); */

      & .image {
        height: 100px;
        img {
          height: 100px;
        }
      }

      & .container {
        height: 360px;
        z-index: 1;

        & .content {
          height: 100%;

          & .body {
            -webkit-line-clamp: 8;
            height: 142px;
            margin-bottom: 20px;
          }

          & a .read-more {
            display: flex;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    .card-focused-large {
      /* margin-top: calc(var(--lokalac-item-gap) * 2);
      margin-bottom: calc(var(--lokalac-item-gap) * 2); */
    }
  }
`;

export const PinIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  color: var(--lokalac-gold);
`;
