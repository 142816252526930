import { createContext, useEffect, useReducer } from 'react';

import { useCookies } from 'react-cookie';

import { feedsReducer, ACTIONS } from '@/reducers/feeds.reducer';

import { categories } from '@/data/categories.data';

const FeedsContext = createContext();

const FeedsProvider = ({ children }) => {
  const [feeds, dispatchFeeds] = useReducer(feedsReducer, {});
  const [cookies, setCookie] = useCookies();

  const value = {
    feeds,
    setData: (value) => dispatchFeeds({ type: ACTIONS.SET_DATA, value }),
  };

  useEffect(() => {
    fetch('/api/v1/feeds/getFeeds', {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.API_KEY,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          value.setData(data.data);
          if (!cookies.feeds) setCookie('feeds', data.data.feeds, { path: '/' });
          if (!cookies.categories)
            setCookie(
              'categories',
              categories.map((category) => category.name),
              { path: '/' }
            );
        }
      })
      .catch(console.log);
  }, []);

  return <FeedsContext.Provider value={value}>{children}</FeedsContext.Provider>;
};

export { FeedsProvider, FeedsContext };
