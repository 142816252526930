import styled from 'styled-components';

export const Container = styled.div`
  font-size: var(--lokalac-xs);
  margin-bottom: 3px;

  .county {
    display: flex;
    align-items: center;

    .show {
      visibility: visible;
    }

    .collapse-toggle {
      margin-left: 10px;
    }

    input {
      margin-right: 10px;

      &:hover {
        cursor: pointer;
      }
    }

    .name-container {
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
      }
    }

    .name {
      margin-right: 5px;
    }
  }

  .cities {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;

    .city {
      display: flex;
      align-items: center;
      margin-left: 50px;

      input {
        cursor: pointer;
      }
      .city-name {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}

.blur-me {
  opacity: 0.6;
}

@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 710px) {
}
@media only screen and (max-width: 425px) {
  .filter-item {
    font-size: var(--lokalac-s);
    margin-bottom: 10px;

    .cities {
      .city {
        display: flex;
        align-items: center;
        margin: 10px 0 1px 50px;

        input {
          cursor: pointer;
        }
        .city-name {
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
  }
`;
