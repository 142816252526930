import { createGlobalStyle } from 'styled-components';

export const cssVars = {
  lokalacPrimaryOne: '#0c67a0',
  lokalacPrimaryTwo: '#0f7ec5',
  lokalacPrimaryThree: '#ccddf2',
  lokalacPrimaryFour: '#89caf4',
  lokalacBackground: '#f1f2f6',
  lokalacTextLight: '#ffffff',
  lokalacTextDark: '#000000',
};

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background: var(--lokalac-background);
  font-family: 'Fira Sans', sans-serif;
  font-size: var(--lokalac-s);
  overflow-y: scroll;
}

a {
  &:link {
    text-decoration: none;
    color: inherit;
  }

  &:visited {
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }
}
`;

export default GlobalStyle;
