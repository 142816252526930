import { PersonalizeLocation } from '@/components';

import { Container } from './personalize-locations.elements';

const PersonalizeLocations = ({
  data,
  newCounties,
  setNewCounties,
  newCities,
  setNewCities,
  newFeeds,
  setNewFeeds,
}) => {
  const sortedCounties = data?.countiesAndCities ? Object.keys(data?.countiesAndCities).sort() : [];

  const handleChange = () => {
    if (data.counties.length !== newCounties.length) {
      setNewFeeds(data.feeds);
      setNewCounties(data.counties);
      setNewCities(data.cities);
    } else {
      setNewFeeds([]);
      setNewCounties([]);
      setNewCities([]);
    }
  };

  return (
    <Container>
      <div className="title">
        S kojih <span>lokacija</span> želiš primati vijesti?
      </div>
      <div className="select-all-locations">
        <div className="primary-option" onClick={handleChange}>
          <input
            type="checkbox"
            name="all-locations"
            id="all-locations"
            checked={data?.counties?.length === newCounties.length}
            onChange={handleChange}
          />
          označi sve županije
        </div>
      </div>
      <div className="locations">
        {sortedCounties.map((county, i) => (
          <PersonalizeLocation
            key={i}
            county={county}
            countyCities={data.countiesAndCities[county].cities}
            newCounties={newCounties}
            setNewCounties={setNewCounties}
            newCities={newCities}
            setNewCities={setNewCities}
            newFeeds={newFeeds}
            setNewFeeds={setNewFeeds}
          />
        ))}
      </div>
    </Container>
  );
};

export default PersonalizeLocations;
