import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  height: 30px;
  padding: 0 10px;
  border-top: 1px solid var(--lokalac-gray-1);
  background: var(--lokalac-background);

  .social {
    display: flex;
    align-items: center;
    color: var(--lokalac-gray-2);

    .text {
      font-size: var(--lokalac-xxs);
    }

    .icons {
      display: flex;
      justify-content: space-between;
      width: 100px;
      margin-left: 20px;
      transition: all 0.2s ease-in-out;
      font-size: var(--lokalac-s);

      .icon {
        position: relative;
        top: 2px;
        left: 0;

        &:hover {
          cursor: pointer;
          color: var(--lokalac-primary-2);
        }
      }
    }
  }

  @media only screen and (max-width: 650px) {
    .social {
      .icons {
        width: 120px;
        font-size: var(--lokalac-l);
      }
    }
  }
`;

export const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  position: relative;
  top: 1px;
  left: 0;

  img {
    max-width: 110px;
    max-height: 18px;
  }
`;
