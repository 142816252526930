import { useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useCookies } from 'react-cookie';

import { UserContext } from '@/context/user.context';

const Auth = () => {
  const { setUser } = useContext(UserContext);
  const router = useRouter();
  const { data: session } = useSession();
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    if (session && session.user) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type: 'fetch-create',
          email: session.user.email,
          firstName: session.user.name.split(' ')[0],
          lastName: session.user.name.split(' ')[1],
          preferences: JSON.stringify({
            categories: cookies.categories,
            feeds: cookies.feeds,
          }),
        }),
      };

      fetch('/api/v1/user/auth', requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            const {
              userId,
              email,
              firstName,
              lastName,
              preferences: dbPreferences,
              isAdmin,
            } = data.user;

            const preferences = JSON.parse(dbPreferences);
            const categories = preferences.categories;
            const feeds = preferences.feeds;

            // Update state with user data
            setUser({
              userId,
              email,
              firstName,
              lastName,
              preferences,
              isAdmin,
              ready: true,
              isAuthenticated: true,
            });

            // Set cookies
            setCookie('categories', categories, { path: '/' });
            setCookie('feeds', feeds, { path: '/' });
            setCookie('userLoggedIn', true, { path: '/' });

            // set JWT to Local Storage
            window.localStorage.setItem('token', data.token);

            // Redirect to home
            router.push('/');
          } else {
            // Redirect to home with error
            router.push('/?error=true');
          }
        })
        .catch(console.log);
    } else null;
  }, [session]);

  return null;
};

export default Auth;
