import styled from 'styled-components';

export const Container = styled.div`
  max-width: 768px;
  margin: 0 auto;
  text-align: justify;

  .subtitle {
    font-size: 1.2rem;
    font-weight: 600;
    font-style: italic;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  p > a {
    color: var(--lokalac-primary-2);
  }

  ul {
    li {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  h3 {
    margin-top: 2rem;
  }
`;
