import { createContext, useState } from 'react';

const UserModalContext = createContext();

const UserModalProvider = ({ children }) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const [userModalType, setUserModalType] = useState('prompt');

  const value = {
    showUserModal,
    setShowUserModal,
    userModalType,
    setUserModalType,
  };

  return <UserModalContext.Provider value={value}>{children}</UserModalContext.Provider>;
};

export { UserModalProvider, UserModalContext };
