import { useContext } from 'react';

import { UserContext } from '@/context/user.context';

import { Table } from '@mantine/core';
import dayjs from 'dayjs';

import { Container, Info } from './admin-panel.element';

const AdminPanel = ({ users }) => {
  const { user } = useContext(UserContext);

  const rows = users.map((user, i) => (
    <tr key={i}>
      <td>{i}</td>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <td>{user.email}</td>
      <td>{dayjs(user.createdAt).format('DD-MMM-YYYY')}</td>
    </tr>
  ));

  if (!user?.isAdmin) return <Container>Zabranjen pristup</Container>;

  return (
    <>
      <Info>Ukupan broj korisnika: {users.length}</Info>
      <Container>
        <Table striped highlightOnHover withBorder withColumnBorders>
          <thead>
            <tr>
              <th>R.b.</th>
              <th>Ime</th>
              <th>Prezime</th>
              <th>Email</th>
              <th>Kreiran</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </Container>
    </>
  );
};

export default AdminPanel;
