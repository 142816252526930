import styled from 'styled-components';

import { cssVars } from '@/styles/global.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: var(--lokalac-item-gap);
  margin-left: calc(var(--lokalac-item-gap) / 2);
  margin-right: calc(var(--lokalac-item-gap) / 2);
  width: var(--lokalac-card-width-normal);
  height: var(--lokalac-card-height-normal);

  background: ${cssVars.lokalacPrimaryTwo};
  color: ${cssVars.lokalacTextLight};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .welcomeName {
      font-size: var(--lokalac-xl);
    }

    .welcome {
      padding-top: 24px;
      font-size: var(--lokalac-xl);
    }

    .welcomeRegistered {
      display: flex;
      font-size: 34px;
      font-weight: 600;
    }

    .register-message {
      text-align: center;
      font-size: var(--lokalac-m);
      margin-top: 30px;
    }

    .registered-question {
      margin-top: 50px;
    }
  }

  .content.registered {
    height: 100%;
    align-items: flex-start;
    justify-content: center;
  }

  @media only screen and (max-width: 650px) {
    margin: 10px auto;
    width: 95%;

    height: auto;

    .content {
      padding: 30px 0;
    }
  }
`;

export const PersonalizeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  background: ${cssVars.lokalacPrimaryThree};
  color: ${cssVars.lokalacPrimaryOne};
  font-weight: 500;
  user-select: none;

  padding: 10px 0;
  width: 75%;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background: ${cssVars.lokalacTextLight};
    color: ${cssVars.lokalacPrimaryTwo};
  }
}
`;

export const SignInButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: ${cssVars.lokalacTextLight};
  font-weight: 500;
  border: 1px solid ${cssVars.lokalacTextLight};
  user-select: none;

  padding: 10px 0;
  width: 75%;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    color: ${cssVars.lokalacPrimaryTwo};
    background: ${cssVars.lokalacTextLight};
    border: 1px solid ${cssVars.lokalacPrimaryTwo};
  }
`;
