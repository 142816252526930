import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .title {
    font-size: var(--lokalac-xxxl);
    color: var(--lokalac-red);
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: var(--lokalac-xxl);
    margin-bottom: 20px;
  }

  .go-back {
    display: flex;
    width: 200px;
    height: 35px;
    justify-content: center;
    align-items: center;
    color: var(--lokalac-black);
    background: var(--lokalac-gold);
    border: 0;
    font-weight: 700;

    user-select: none;
    padding: 8px 16px;
    border-radius: 15px;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
`;
