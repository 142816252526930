import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;

  font-size: var(--lokalac-xxs);
  color: var(--lokalac-text-light);
`
