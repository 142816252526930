export const head = {
  root: {
    title: 'Naslovnica',
    description: 'Čitajte vijesti koje su vam najbliže i najbitnije. Lokalac.',
  },
  auth: {
    title: 'Prijava u tijeku',
    description: '',
    noIndex: true,
  },
  'auth#_=_': {
    title: 'Prijava u tijeku',
    description: '',
    noIndex: true,
  },
  admin: {
    title: 'Administracija',
    description: '',
    noIndex: true,
  },
  sve: {
    title: 'Naslovnica',
    description: 'Čitajte vijesti koje su vam najbliže i najbitnije. Lokalac.',
  },
  personaliziraj: {
    title: 'Personaliziraj',
    description:
      'Personaliziratje vijesti koje želite čitati - kategorije, županije, gradovi, portali...',
    noIndex: true,
  },
  'uvjeti-koristenja': {
    title: 'Uvjeti Korištenja',
    description: 'Uvjeti korištenja portala Lokalac.hr.',
    noIndex: true,
  },
  'politika-privatnosti': {
    title: 'Politika Privatnosti',
    description: 'Politika privatnosti za portal Lokalac.hr.',
    noIndex: true,
  },
  'politika-kolacica': {
    title: 'Politika Kolačića',
    description: 'Politika kolačića za portal Lokalac.hr.',
    noIndex: true,
  },
  'postavljanje-lozinke': {
    title: 'Postavljanje lozinke',
    description: '',
    noIndex: true,
  },
  faq: {
    title: 'FAQ',
    description: 'Najčešće postavljanja pitanja za portal Lokalac.hr.',
    noIndex: true,
  },
  kultura: {
    title: 'Kultura',
    description: 'Čitajte vijesti iz kulture koje su vam najbliže i najbitnije. Lokalac.',
  },
  'pravo-i-pravda': {
    title: 'Pravo i pravda',
    description: 'Čitajte vijesti iz prava i pravde koje su vam najbliže i najbitnije. Lokalac.',
  },
  incidenti: {
    title: 'Incidenti',
    description: 'Čitajte vijesti iz incidenata koje su vam najbliže i najbitnije. Lokalac.',
  },
  'ekonomija-i-biznis': {
    title: 'Ekonomija i biznis',
    description:
      'Čitajte vijesti iz ekonomije i biznisa koje su vam najbliže i najbitnije. Lokalac.',
  },
  edukacija: {
    title: 'Edukacija',
    description: 'Čitajte vijesti iz edukacije koje su vam najbliže i najbitnije. Lokalac.',
  },
  okoliš: {
    title: 'Okoliš',
    description: 'Čitajte vijesti iz okoliša koje su vam najbliže i najbitnije. Lokalac.',
  },
  zdravlje: {
    title: 'Zdravlje',
    description: 'Čitajte vijesti iz zdravlja koje su vam najbliže i najbitnije. Lokalac.',
  },
  lifestyle: {
    title: 'Lifestyle',
    description: 'Čitajte lifestyle vijesti koje su vam najbliže i najbitnije. Lokalac.',
  },
  posao: {
    title: 'Posao',
    description: 'Čitajte poslovne vijesti koje su vam najbliže i najbitnije. Lokalac.',
  },
  politika: {
    title: 'Politika',
    description: 'Čitajte vijesti iz politike koje su vam najbliže i najbitnije. Lokalac.',
  },
  religija: {
    title: 'Religija',
    description: 'Čitajte vijesti iz religije koje su vam najbliže i najbitnije. Lokalac.',
  },
  'znanost-i-tehnologija': {
    title: 'Znanost i tehnologija',
    description:
      'Čitajte vijesti iz znanosti i tehnologije koje su vam najbliže i najbitnije. Lokalac.',
  },
  vijesti: {
    title: 'Vijesti',
    description: 'Čitajte vijesti koje su vam najbliže i najbitnije. Lokalac.',
  },
  sport: {
    title: 'Sport',
    description: 'Čitajte vijesti iz sporta koje su vam najbliže i najbitnije. Lokalac.',
  },
  sukobi: {
    title: 'Sukobi',
    description: 'Čitajte vijesti iz sukoba koje su vam najbliže i najbitnije. Lokalac.',
  },
  vrijeme: {
    title: 'Vrijeme',
    description: 'Čitajte vijesti za vrijeme koje su vam najbliže i najbitnije. Lokalac.',
  },
};
