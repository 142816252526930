import Image from 'next/image';
import styled, { css } from 'styled-components';
import { keyframes } from 'styled-components';

const fadeIn = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1;}
`;

const fadeInDelay = keyframes`
  0% { opacity: 0; }
 20% { opacity: 0; }
 100% { opacity: 1;}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  top: ${({ $isVisible }) => ($isVisible ? '0' : '-80px')};
  left: 0;

  color: var(--lokalac-text-light);
  z-index: 18;
  transition: top 0.6s ease-in-out;
  user-select: none;

  animation-name: ${fadeIn};
  animation-duration: 2s;
  animation-iteration-count: 1;

  background: var(--lokalac-primary-2);
`;

export const UpperHeader = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background: ${({ $isAuthenticated }) =>
    $isAuthenticated ? 'var(--lokalac-white)' : 'var(--lokalac-primary-2)'};
  width: 100%;
  height: 40px;
  max-width: var(--lokalac-content-max-width);

  animation-name: ${fadeInDelay};
  animation-duration: 2s;
  animation-iteration-count: 1;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  height: 100%;

  position: relative;
  top: 3px;
`;

export const LogoLink = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Logo = styled(Image)`
  height: 25px;

  ${({ $isAuthenticated }) =>
    $isAuthenticated
      ? css`
          animation-name: ${fadeIn};
          animation-duration: 2s;
          animation-iteration-count: 1;

          @media screen and (max-width: 769px) {
            animation-duration: 1s;
          }
        `
      : ''};
`;

export const LowerHeader = styled.div`
  display: ${({ $light }) => ($light ? 'none' : 'flex')};
  align-items: center;
  background: var(--lokalac-primary-1);
  height: 40px;
  width: 100%;
`;

export const RightSide = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 40%;

  ${({ $isAuthenticated }) =>
    $isAuthenticated
      ? css`
          animation-name: ${fadeIn};
          animation-duration: 2s;
          animation-iteration-count: 1;
        `
      : ''};

  @media screen and (max-width: 769px) {
    animation-duration: 1s;
  }
`;

export const SelectOptions = styled.div`
  display: ${({ $light }) => ($light ? 'none' : 'flex')};
  align-items: center;

  @media screen and (max-width: 1400px) {
    display: none;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 25px;
  font-weight: ${({ $isSelected }) => ($isSelected ? 500 : 400)};
  background: ${({ $isSelected, $isAuthenticated }) =>
    $isSelected
      ? 'var(--lokalac-primary-1)'
      : $isAuthenticated
      ? 'var(--lokalac-white)'
      : 'var(--lokalac-primary-2)'};

  color: ${({ $isSelected, $isAuthenticated }) =>
    $isSelected
      ? 'var(--lokalac-white)'
      : $isAuthenticated
      ? 'var(--lokalac-primary-1)'
      : 'var(--lokalac-white)'};

  &:hover {
    cursor: ${({ $isSelected }) => ($isSelected ? 'default' : 'pointer')};
  }
`;

export const UserArea = styled.div`
  display: flex;
  height: 40px;
  margin-left: auto;

  @media screen and (max-width: 768px) {
    display: ${({ $isAuthenticated }) => ($isAuthenticated ? 'none' : 'flex')};
  }
`;

export const SignIn = styled.button`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  width: 120px;
  padding: 0 30px;
  background: var(--lokalac-white);
  color: var(--lokalac-primary-1);
  font-size: var(--lokalac-s);
  border-radius: 77px 0 0 77px;
  border: none;
  outline: none;
  transition: width 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    width: 140px;
  }
`;

const signInAnimation = keyframes`
 0% { margin-right: -100%; opacity: 1; }
 95% {  opacity: 1; }
 100% { margin-right: 0%; opacity: 0; border-radius: 77px 0 0 77px; }
`;

export const LoginAnimationContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  width: 100%;
  height: 100%;
  margin-right: -100%;

  color: black;
  ${({ $isAuthenticated }) =>
    $isAuthenticated
      ? css`
          animation-name: ${signInAnimation};
          animation-duration: 3s;
          animation-iteration-count: 1;

          @media screen and (max-width: 769px) {
            animation-duration: 2s;
          }
        `
      : ''};
`;

export const SignOut = styled.button`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  padding: 0 30px;
  background: var(--lokalac-white);
  color: var(--lokalac-primary-1);
  font-size: var(--lokalac-s);
  border: none;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;

export const Preferences = styled.div`
  display: ${({ $light }) => ($light ? 'none' : 'flex')};
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  padding: 0 30px;
  background: var(--lokalac-white);

  font-size: var(--lokalac-s);
  border: none;
  outline: none;
  color: var(--lokalac-primary-1);

  &:hover {
    cursor: pointer;
  }
`;

export const Categories = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 16px;

  @media screen and (max-width: 1560px) {
    padding: 0 8px;

    .mantine-Badge-inner {
      font-size: 0.78rem;
    }
  }

  @media screen and (max-width: 1500px) {
    .mantine-Badge-inner {
      font-size: 0.7rem;
      letter-spacing: 0.017rem;
    }
  }

  @media screen and (max-width: 1400px) {
    display: none;
  }
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  margin: 0 18px;
  height: 40px;
  font-size: 13px;

  &:hover {
    cursor: pointer;
  }
`;

export const LocationsContainer = styled.div`
  .Collapsible-filter {
    margin: 0;
    padding: 0;

    position: absolute;
    top: 40px;
  }

  .Collapsible-filter .Collapsible__contentInner {
    margin-top: 0;
  }

  @media screen and (max-width: 1399px) {
    .Collapsible-filter {
      top: 80px;
    }
  }
`;

export const CategoriesMenu = styled.div`
  .Collapsible-categories {
    width: 100%;
    margin: 0;
    padding: 0;

    position: absolute;
    top: 40px;
    right: 0;
  }

  .Collapsible-categories .Collapsible__contentInner {
    margin-top: 0;
  }

  @media screen and (max-width: 1399px) {
    .Collapsible-categories {
      top: 80px;
    }
  }

  @media screen and (min-width: 1399px) {
    .Collapsible-categories {
      display: none;
    }
  }
`;

export const CategoriesMenuItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  width: 100%;
  gap: 16px;
  padding: 16px 24px;

  background: var(--lokalac-primary-1);

  @media screen and (max-width: 425px) {
    gap: 16px 8px;
  }
`;

export const CategoryMenuItem = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 170px;

  @media screen and (max-width: 1024px) {
    width: 100px;
    font-size: 14px;
    text-transform: uppercase;
  }
`;

export const SmallScreenIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 20px;

  @media screen and (min-width: 1399px) {
    display: none;
  }
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;

  > i {
    margin-left: 5px;
    font-size: 13px;
  }
`;

export const SmallScreenUserArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  color: var(--lokalac-text-dark);
  margin-left: auto;
  position: relative;
  z-index: 5;

  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export const UserIconWrapper = styled.div``;

export const UserDropBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.2);

  position: absolute;
  top: 40px;
  right: 0;
  width: 200px;

  > * {
    width: 100%;
    height: 60px;

    justify-content: flex-start;
  }
`;

export const RssContainer = styled.a`
  display: ${({ $light }) => ($light ? 'none' : 'flex')};

  justify-content: center;
  align-items: center;

  height: 100%;

  border-radius: 4px;
  border: 1px solid var(--lokalac-primary-1);
  width: 32px;
  height: 32px;

  margin: auto 16px;

  > svg {
    color: var(--lokalac-primary-1);
  }

  &:hover {
    background: var(--lokalac-primary-2);
    border: 1px solid white;
    > svg {
      color: white;
    }
  }
`;

export const AdminIcon = styled.div`
  display: ${({ $light }) => ($light ? 'none' : 'flex')};

  justify-content: center;
  align-items: center;

  height: 100%;

  width: 32px;
  height: 32px;

  margin: auto 16px;

  color: var(--lokalac-primary-1);

  &:hover {
    cursor: pointer;
    color: var(--lokalac-primary-2);
  }
`;
