import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

import { useCookies } from 'react-cookie';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Store } from 'react-notifications-component';

import { InfoBox, Card, NoData } from '@/components';

import { Container } from './feed.elements';

import useDeviceSize from '@/hooks/use-device-size.hook';
import { slugify } from '@/utils/functions/helper.functions';

const largeCardMatrix = {
  three: [
    3, 17, 21, 30, 34, 43, 47, 56, 60, 69, 73, 82, 86, 95, 99, 108, 112, 121, 125, 134, 138, 147,
    151, 160, 164, 173, 177, 186, 190, 199, 203, 212, 216, 225, 229, 238, 242, 251, 255, 264, 268,
    277, 281, 290, 294, 303, 307, 316, 320, 329, 333, 342, 346, 355, 359, 368, 372, 381, 385, 394,
    398, 407, 411, 420, 424, 433, 437, 446, 450, 459, 463, 472, 476, 485, 489, 498, 502, 511, 515,
    524, 528, 537, 541, 550, 554, 563, 567, 576, 580, 589, 593, 602, 606, 615, 619, 628, 632, 641,
    645, 654, 658, 667, 671, 680, 684, 693, 697, 706, 710, 719, 723, 732, 736, 745, 749, 758, 762,
    771, 775, 784, 788, 797, 801, 810, 814, 823, 827, 836, 840, 849, 853, 862, 866, 875, 879, 888,
    892, 901, 905, 914, 918, 927, 931, 940, 944, 953, 957, 966, 970, 979, 983, 992, 996, 1005, 1009,
    1018, 1022, 1031, 1035, 1044, 1048, 1057, 1061, 1070, 1074, 1083, 1087, 1096, 1100, 1109, 1113,
    1122, 1126, 1135, 1139, 1148, 1152, 1161, 1165, 1174, 1178, 1187, 1191, 1200, 1204, 1213, 1217,
    1226, 1230, 1239, 1243, 1252, 1256, 1265, 1269, 1278, 1282, 1291, 1295, 1304, 1308, 1317, 1321,
    1330, 1334, 1343, 1347, 1356, 1360, 1369, 1373, 1382, 1386, 1395, 1399, 1408, 1412, 1421, 1425,
    1434, 1438, 1447, 1451, 1460, 1464, 1473, 1477, 1486, 1490, 1499, 1503, 1512, 1516, 1525, 1529,
    1538, 1542, 1551, 1555, 1564, 1568, 1577, 1581, 1590, 1594, 1603, 1607, 1616, 1620, 1629, 1633,
    1642, 1646, 1655, 1659, 1668, 1672, 1681, 1685, 1694, 1698, 1707, 1711, 1720, 1724, 1733, 1737,
    1746, 1750, 1759, 1763, 1772, 1776, 1785, 1789, 1798, 1802, 1811, 1815, 1824, 1828, 1837, 1841,
    1850, 1854, 1863, 1867, 1876, 1880, 1889, 1893, 1902, 1906, 1915, 1919, 1928, 1932, 1941, 1945,
    1954, 1958, 1967, 1971, 1980, 1984, 1993, 1997, 2006, 2010, 2019, 2023, 2032, 2036, 2045, 2049,
    2058, 2062, 2071, 2075, 2084, 2088, 2097, 2101, 2110, 2114, 2123, 2127, 2136, 2140, 2149, 2153,
    2162, 2166, 2175, 2179, 2188, 2192, 2201, 2205, 2214, 2218, 2227, 2231, 2240, 2244, 2253, 2257,
    2266, 2270, 2279, 2283, 2292, 2296, 2305, 2309, 2318, 2322, 2331, 2335, 2344, 2348, 2357, 2361,
    2370, 2374, 2383, 2387, 2396, 2400, 2409, 2413, 2422, 2426, 2435, 2439, 2448, 2452, 2461, 2465,
    2474, 2478, 2487, 2491, 2500, 2504, 2513, 2517, 2526, 2530, 2539, 2543, 2552, 2556, 2565, 2569,
    2578, 2582, 2591, 2595, 2604, 2608, 2617, 2621, 2630, 2634, 2643, 2647, 2656, 2660, 2669, 2673,
    2682, 2686, 2695, 2699, 2708, 2712, 2721, 2725, 2734, 2738, 2747, 2751, 2760, 2764, 2773, 2777,
    2786, 2790, 2799, 2803, 2812, 2816, 2825, 2829, 2838, 2842, 2851, 2855, 2864, 2868, 2877, 2881,
    2890, 2894, 2903, 2907, 2916, 2920, 2929, 2933, 2942, 2946, 2955, 2959, 2968, 2972, 2981, 2985,
    2994, 2998, 3007, 3011, 3020, 3024, 3033, 3037, 3046, 3050, 3059, 3063, 3072, 3076, 3085, 3089,
    3098, 3102, 3111, 3115, 3124, 3128, 3137, 3141, 3150, 3154, 3163, 3167, 3176, 3180, 3189, 3193,
    3202, 3206, 3215, 3219, 3228, 3232, 3241, 3245, 3254, 3258,
  ],

  four: [
    1, 6, 14, 22, 23, 27, 35, 36, 40, 48, 49, 53, 61, 62, 66, 74, 75, 79, 87, 88, 92, 100, 101, 105,
    113, 114, 118, 126, 127, 131, 139, 140, 144, 152, 153, 157, 165, 166, 170, 178, 179, 183, 191,
    192, 196, 204, 205, 209, 217, 218, 222, 230, 231, 235, 243, 244, 248, 256, 257, 261, 269, 270,
    274, 282, 283, 287, 295, 296, 300, 308, 309, 313, 321, 322, 326, 334, 335, 339, 347, 348, 352,
    360, 361, 365, 373, 374, 378, 386, 387, 391, 399, 400, 404, 412, 413, 417, 425, 426, 430, 438,
    439, 443, 451, 452, 456, 464, 465, 469, 477, 478, 482, 490, 491, 495, 503, 504, 508, 516, 517,
    521, 529, 530, 534, 542, 543, 547, 555, 556, 560, 568, 569, 573, 581, 582, 586, 594, 595, 599,
    607, 608, 612, 620, 621, 625, 633, 634, 638, 646, 647, 651, 659, 660, 664, 672, 673, 677, 685,
    686, 690, 698, 699, 703, 711, 712, 716, 724, 725, 729, 737, 738, 742, 750, 751, 755, 763, 764,
    768, 776, 777, 781, 789, 790, 794, 802, 803, 807, 815, 816, 820, 828, 829, 833, 841, 842, 846,
    854, 855, 859, 867, 868, 872, 880, 881, 885, 893, 894, 898, 906, 907, 911, 919, 920, 924, 932,
    933, 937, 945, 946, 950, 958, 959, 963, 971, 972, 976, 984, 985, 989, 997, 998, 1002, 1010,
    1011, 1015, 1023, 1024, 1028, 1036, 1037, 1041, 1049, 1050, 1054, 1062, 1063, 1067, 1075, 1076,
    1080, 1088, 1089, 1093, 1101, 1102, 1106, 1114, 1115, 1119, 1127, 1128, 1132, 1140, 1141, 1145,
    1153, 1154, 1158, 1166, 1167, 1171, 1179, 1180, 1184, 1192, 1193, 1197, 1205, 1206, 1210, 1218,
    1219, 1223, 1231, 1232, 1236, 1244, 1245, 1249, 1257, 1258, 1262, 1270, 1271, 1275, 1283, 1284,
    1288, 1296, 1297, 1301, 1309, 1310, 1314, 1322, 1323, 1327, 1335, 1336, 1340, 1348, 1349, 1353,
    1361, 1362, 1366, 1374, 1375, 1379, 1387, 1388, 1392, 1400, 1401, 1405, 1413, 1414, 1418, 1426,
    1427, 1431, 1439, 1440, 1444, 1452, 1453, 1457, 1465, 1466, 1470, 1478, 1479, 1483, 1491, 1492,
    1496, 1504, 1505, 1509, 1517, 1518, 1522, 1530, 1531, 1535, 1543, 1544, 1548, 1556, 1557, 1561,
    1569, 1570, 1574, 1582, 1583, 1587, 1595, 1596, 1600, 1608, 1609, 1613, 1621, 1622, 1626, 1634,
    1635, 1639, 1647, 1648, 1652, 1660, 1661, 1665, 1673, 1674, 1678, 1686, 1687, 1691, 1699, 1700,
    1704, 1712, 1713, 1717, 1725, 1726, 1730, 1738, 1739, 1743, 1751, 1752, 1756, 1764, 1765, 1769,
    1777, 1778, 1782, 1790, 1791, 1795, 1803, 1804, 1808, 1816, 1817, 1821, 1829, 1830, 1834, 1842,
    1843, 1847, 1855, 1856, 1860, 1868, 1869, 1873, 1881, 1882, 1886, 1894, 1895, 1899, 1907, 1908,
    1912, 1920, 1921, 1925, 1933, 1934, 1938, 1946, 1947, 1951, 1959, 1960, 1964, 1972, 1973, 1977,
    1985, 1986, 1990, 1998, 1999, 2003, 2011, 2012, 2016, 2024, 2025, 2029, 2037, 2038, 2042, 2050,
    2051, 2055, 2063, 2064, 2068, 2076, 2077, 2081, 2089, 2090, 2094, 2102, 2103, 2107, 2115, 2116,
    2120, 2128, 2129, 2133, 2141, 2142, 2146, 2154, 2155, 2159, 2167, 2168, 2172, 2180, 2181,
  ],
};

const Feed = ({ categories, articles: initArticles, portal, userLoggedIn: initUserLoggedIn }) => {
  const [articles, setArticles] = useState(initArticles);
  const [start, setStart] = useState(initArticles?.length);
  const [userLoggedIn, setUserLoggedIn] = useState(initUserLoggedIn);
  const [hasMore, setHasMore] = useState(true);
  const [cookies, setCookie] = useCookies(['feeds']);
  const [width] = useDeviceSize();
  const router = useRouter();
  const { error } = router.query;

  // Ako se promijeni preferences feeds, refresh sadrzaj
  useEffect(() => {
    refreshData();
  }, [cookies.feeds, userLoggedIn]);

  // Postavi nove clanke sa servera u state
  useEffect(() => {
    setArticles(initArticles);
    setStart(initArticles.length);

    if (error) {
      Store.addNotification({
        title: 'Nešto je pošlo po zlu.',
        message: 'Molimo pokušajte ponovo.',
        type: 'warning',
        insert: 'top',
        container: 'top-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          delay: 500,
          duration: 2000,
          onScreen: false,
        },
      });
    }
  }, [initArticles]);

  // Inicijalno testiraj clanke da li ih ima jos po tim kriterijima u bazi
  useEffect(() => {
    if (initArticles.length < process.env.ARTICLES_COUNT) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [initArticles]);

  // Change user logged in status if signed out
  useEffect(() => {
    setUserLoggedIn(cookies.userLoggedIn ? true : false);
  }, [cookies.userLoggedIn]);

  const refreshData = () => {
    setHasMore(true);

    const reqOptions = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.API_KEY,
      },
      body: JSON.stringify({
        feeds: cookies.feeds,
        categories: cookies.categories || categories,
        portal,
        start: 0,
        count: process.env.ARTICLES_COUNT,
      }),
    };

    fetch('/api/v1/articles/getArticles', reqOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setArticles(data.data);
          setStart(data.count);

          if (data.count < process.env.ARTICLES_COUNT) {
            setHasMore(false);
          }
        }
      })
      .catch(console.log);
  };

  const fetchNewData = () => {
    const reqOptions = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.API_KEY,
      },
      body: JSON.stringify({
        feeds: cookies.feeds,
        categories: cookies.categories || categories,
        portal,
        start,
        count: process.env.ARTICLES_COUNT,
      }),
    };

    fetch('/api/v1/articles/getArticles', reqOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setArticles(articles.concat(data.data));
          setStart((prev) => prev + data.count);
          if (data.count < process.env.ARTICLES_COUNT) setHasMore(false);
        }
      })
      .catch(console.log);
  };

  const handleLarge = (i) => {
    if (width >= 1436) {
      // 4 stupca
      // 3 stupca
      if (largeCardMatrix['four'].includes(i)) return 1;

      return 0;
    } else if (width >= 964) {
      // 3 stupca
      if (largeCardMatrix['three'].includes(i)) return 1;

      return 0;
    } else if (width >= 652) {
      // 2 stupca
      if (i % 3 === 0 && i > 0) return 1;
      return 0;
    } else {
      // 1 stupac
      if (i % 3 === 0 && i > 0) return 1;
      return 0;
    }
  };

  return articles.length > 0 && width ? (
    <Container>
      <InfiniteScroll
        dataLength={articles.length}
        next={fetchNewData}
        scrollThreshold={0.5}
        hasMore={hasMore}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: 'center', opacity: 0.7 }}>&#8595; Povuci da osvježiš</h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: 'center', opacity: 0.7 }}>&#8593; Pusti i osvježi</h3>
        }
        refreshFunction={refreshData}
        loader={
          <p style={{ textAlign: 'center', marginBottom: 30, opacity: 0.7 }}>
            <b>Učitavam...</b>
          </p>
        }
        endMessage={
          <p style={{ textAlign: 'center', marginBottom: 30, opacity: 0.7 }}>
            <b>Kraj sadržaja</b>
          </p>
        }
      >
        <div
          className={`feed grid ${!userLoggedIn ? 'feed-more-padding ' : 'feed-less-padding '} `}
        >
          <InfoBox />
          {articles.map((item, i) => (
            <Card
              key={i}
              sourceName={item.source}
              articleUrl={item.articleUrl}
              category={slugify(item.category)}
              imageUrl={item.imageUrl}
              title={item?.title || ''}
              body={item.body}
              pubTime={item.pubTime}
              large={handleLarge(i)}
              pinned={item.articles_native?.pinned}
            />
          ))}
        </div>
      </InfiniteScroll>
    </Container>
  ) : (
    <Container>
      <NoData />
    </Container>
  );
};

export default Feed;
