import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 2%;
  min-width: 900px;

  overflow: scroll;
`;

export const Info = styled.div`
  padding: 20px 2%;
`;
