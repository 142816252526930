import { useState, useEffect, useContext } from 'react';

import { Store } from 'react-notifications-component';

import { UserModalContext } from '@/context/user-modal.context';

import AvTimerIcon from '@material-ui/icons/AvTimer';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const UserResetPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [tokenOk, setTokenOk] = useState(false);
  const { setUserModalType } = useContext(UserModalContext);

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    const email = new URLSearchParams(window.location.search).get('email');

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token, email }),
    };

    fetch('/api/v1/user/authResetToken', requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setIsLoading(false);
          setTokenOk(true);
        } else {
          setIsLoading(false);
          setTokenOk(false);
        }
      })
      .catch(console.log)
      .then(setIsLoading(false));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const email = new URLSearchParams(window.location.search).get('email');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        newPassword,
        confirmPassword,
      }),
    };

    setIsUpdating(true);
    fetch('/api/v1/user/resetPassword', requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setIsUpdating(200);
          setNewPassword('');
          setConfirmPassword('');
          setUserModalType('signIn');

          Store.addNotification({
            title: 'Uspješno postavljena lozinka!',
            message: 'Dobrodošli.',
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              delay: 500,
              duration: 2000,
              onScreen: false,
            },
          });
        }
        if (!data.success) setIsUpdating(400);
        setTimeout(() => setIsUpdating(false), 3000);
      })
      .catch(console.log)
      .then(setTimeout(() => setIsUpdating(false), 3000));
  };

  return isLoading ? (
    'Loading...'
  ) : tokenOk ? (
    <form className="modal-box reset-password-box">
      <div className="title">Postavi novu lozinku.</div>
      <div className="inputs">
        <div className="form-control">
          <input
            type="password"
            name="newPassword"
            id="newPassword"
            autoFocus
            placeholder="Nova lozinka"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className={newPassword.length > 0 && newPassword < 6 ? 'validation-error' : null}
          />
          <small>
            {newPassword.length > 0 && newPassword < 6
              ? 'Lozinka mora biti najmanje 6 znakova.'
              : null}
          </small>
        </div>
        <div className="form-control">
          <input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Potvrdi lozinku"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className={
              newPassword.length > 0 && newPassword < 6 && newPassword !== confirmPassword
                ? 'validation-error'
                : null
            }
          />
          <small>
            {newPassword.length > 5 && newPassword !== confirmPassword
              ? 'Lozinke se ne podudaraju.'
              : null}
          </small>
        </div>
      </div>
      <div className="buttons">
        <button
          type="submit"
          disabled={newPassword.length < 6 || newPassword !== confirmPassword || isUpdating}
          className="button button-lokalac"
          onClick={handleSubmit}
        >
          {isUpdating ? (
            <AvTimerIcon />
          ) : isUpdating === 200 ? (
            <DoneAllIcon />
          ) : isUpdating === 400 ? (
            <ErrorOutlineIcon />
          ) : (
            'Postavi lozinku'
          )}
        </button>
      </div>
    </form>
  ) : !tokenOk ? (
    <div className="modal-box reset-password-box">
      <div className="error-message">Token je istekao. Pokušajte resetirati lozinku ponovo.</div>
      <div className="buttons">
        <button className="button button-lokalac" onClick={() => setUserModalType('forgotPwd')}>
          Resetiraj lozinku
        </button>
      </div>
    </div>
  ) : null;
};

export default UserResetPassword;
