import styled from 'styled-components';

export const Container = styled.main`
  padding: 50px 100px;

  h1 {
    font-size: var(--lokalac-xxxl);
    font-weight: 500;
    margin-bottom: 1rem;
    color: var(--lokalac-text-dark);
  }

  h2 {
    font-size: var(--lokalac-xl);
    font-weight: 500;
    margin-top: 4rem;
    color: var(--lokalac-text-dark);
  }

  p {
    margin: 0.25rem;
    font-size: var(--lokalac-l);
  }

  .top-row {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 30px;

    .button {
      height: 2.4rem;
      width: 100px;
      justify-content: center;
      display: flex;
      align-items: center;
      background: var(--lokalac-primary-1);
      color: var(--lokalac-text-light);
      font-weight: bold;
      font-size: 0.8rem;
      letter-spacing: 1px;
      padding: 0 0.8rem;
      margin: 0 1rem;
      border-radius: 5px;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        background: var(--lokalac-primary-2);
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
    padding: 100px 50px;
}
@media only screen and (max-width: 710px) {
}
@media only screen and (max-width: 425px) {
}
`;
