import { createContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ ready: false });
  const router = useRouter();
  const [cookies, setCookie, removeCookie] = useCookies();

  const value = {
    user,
    setUser,
  };

  useEffect(() => {
    const token = window.localStorage.getItem('token');
    if (token) {
      fetch('/api/v1/user/signIn', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            const {
              userId,
              email,
              firstName,
              lastName,
              preferences: dbPreferences,
              isAdmin,
            } = data.user;
            const preferences = JSON.parse(dbPreferences);
            const categories = preferences.categories;
            const feeds = preferences.feeds;

            setUser({
              ready: true,
              isAuthenticated: true,
              userId,
              email,
              firstName,
              lastName,
              preferences,
              isAdmin,
            });

            // Set cookies
            setCookie('feeds', feeds, { path: '/' });
            setCookie('userLoggedIn', true, { path: '/' });
            if (!router.query.category) setCookie('categories', categories, { path: '/' });
          } else {
            window.localStorage.removeItem('token');
            setUser({ ready: true, isAuthenticated: false });

            removeCookie('categories');
            removeCookie('feeds');
            removeCookie('userLoggedIn');
          }
        })
        .catch(console.log);
    } else {
      setUser({ ready: true, isAuthenticated: false });
    }
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
