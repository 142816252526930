import { categories } from '@/data/categories.data';

import { Container } from './personalize-categories.elements';

const PersonalizeCategories = ({ newCategories, setNewCategories }) => {
  const handleChange = (e) => {
    let deltaCategories =
      e.target.id === 'Sve'
        ? categories.map((category) => category.name)
        : newCategories.length === categories.length
        ? [e.target.id]
        : newCategories.includes(e.target.id)
        ? newCategories.filter((category) => category !== e.target.id)
        : [...newCategories, e.target.id];

    if (deltaCategories.length === 0 || deltaCategories.length === categories.length)
      deltaCategories = categories.map((category) => category.name);

    setNewCategories(deltaCategories);
  };

  return (
    <Container>
      <div className="title">
        Koje <span>kategorije</span> te zanimaju?
      </div>
      <div className="categories">
        <div className="col-1">
          <div className="form-control">
            <div className="primary-option" id="Sve" onClick={handleChange}>
              <input
                type="checkbox"
                name="all-categories"
                id="Sve"
                checked={newCategories.length === categories.length}
                onChange={handleChange}
              />
              sve kategorije
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="container">ili odaberi neke:</div>
        </div>
        <div className="col-3">
          {categories
            .filter((category) => category.name !== 'Sve')
            .map((category, i) => (
              <div className="form-control" key={i}>
                <input
                  type="checkbox"
                  name={category.slug}
                  id={category.name}
                  checked={
                    newCategories.length !== categories.length &&
                    newCategories.includes(category.name)
                  }
                  onChange={handleChange}
                />
                <div
                  className={`secondary-option type-${category.slug}`}
                  id={category.name}
                  onClick={handleChange}
                >
                  {category.name}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Container>
  );
};

export default PersonalizeCategories;
