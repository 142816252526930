import { useEffect, useContext } from 'react';

import { UserModalContext } from '@/context/user-modal.context';

import {
  UserPrompt,
  UserRegister,
  UserLogin,
  UserForgotPassword,
  UserResetPassword,
} from '@/components';

import { Container } from './user-modal.elements';

const UserModal = ({ show }) => {
  const { setShowUserModal, userModalType, setUserModalType } = useContext(UserModalContext);

  const closeModal = (e) => {
    if (document.querySelector('.modal-close-x')) {
      if (e.target.classList.contains('modal-close-x') || e.key === 'Escape') {
        setShowUserModal(false);
        setUserModalType('prompt');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('click', closeModal);
    window.addEventListener('keydown', function (e) {
      if (e.key === 'Escape') closeModal(e);
    });
    return () => {
      window.removeEventListener('click', closeModal);
      window.removeEventListener('keydown', null);
    };
  });

  return show ? (
    <Container>
      {userModalType === 'prompt' ? (
        <UserPrompt />
      ) : userModalType === 'signUp' ? (
        <UserRegister />
      ) : userModalType === 'signIn' ? (
        <UserLogin />
      ) : userModalType === 'forgotPwd' ? (
        <UserForgotPassword />
      ) : userModalType === 'resetPwd' ? (
        <UserResetPassword />
      ) : null}
    </Container>
  ) : null;
};
export default UserModal;
