import { useState } from 'react';
import { useRouter } from 'next/router';

import { decode } from 'html-entities';
import Image from 'next/image';
import { useCookies } from 'react-cookie';
import VisibilitySensor from 'react-visibility-sensor';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'za %s',
    past: 'prije %s',
    s: 'nekoliko sekundi',
    m: 'jedne minute',
    mm: '%d minuta',
    h: 'jednog sata',
    hh: '%d sati',
    d: 'jednog dana',
    dd: '%d dana',
    M: 'jednog mjeseca',
    MM: '%d mjeseci',
    y: 'jedne godine',
    yy: '%d godina',
  },
});

import { CardFooter } from '@/components';

import { Container, PinIcon } from './card.elements';

import { scrollTop } from '@/utils/functions/helper.functions';
import { categories } from '@/data/categories.data';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import StarIcon from '@material-ui/icons/Star';

const Card = ({
  category,
  imageUrl,
  sourceName,
  articleUrl,
  title,
  body,
  pubTime,
  large,
  small,
  pinned,
}) => {
  const [cookies, setCookie] = useCookies();
  const [visible, setVisible] = useState(false);
  const router = useRouter();

  const onVisible = ($isVisible) => (window.innerWidth < 500 ? setVisible($isVisible) : null);

  const handleCategoryClick = (category) => {
    setCookie(
      'categories',
      category === 'sve' ? categories.map((category) => category.name) : [category],
      { path: '/' }
    );

    router.push(`${category === 'sve' ? '/' : `/${category}`}`);
    scrollTop();
  };

  return large ? (
    <VisibilitySensor onChange={onVisible} partialVisibility offset={{ top: 500, bottom: 500 }}>
      <Container>
        <div className={`card-large ${visible ? 'card-focused-large' : null}`}>
          <a href={articleUrl} target="_blank" rel="noreferrer">
            <div className="image">
              <Image
                src={imageUrl}
                alt={title}
                width={500}
                height={200}
                style={{ objectFit: 'cover', maxWidth: '100%' }}
              />
              <div className="gradient"></div>
              {pinned ? (
                <PinIcon>
                  <StarIcon fontSize="medium" />
                </PinIcon>
              ) : null}
            </div>
          </a>
          <div className="container">
            <div className="meta">
              <div className="category-container" onClick={() => handleCategoryClick(category)}>
                <div className={`category type-${category}`}>{category.replaceAll('-', ' ')}</div>
              </div>
              <div className="divider">|</div>
              <div className="time">{dayjs(pubTime).fromNow()}</div>
            </div>
            <div className="content">
              <a href={articleUrl} className="title" target="_blank" rel="noreferrer">
                {decode(title.replace(/  +/g, ' '))}
              </a>
              {body.length > 0 ? (
                <div className="body">{decode(body.replace(/  +/g, ' '))}</div>
              ) : (
                <div className="body-empty"></div>
              )}
              <a href={articleUrl} target="_blank" rel="noreferrer">
                <div className="read-more">
                  <span>Pročitaj članak</span>
                  <ChevronRightIcon fontSize="small" />
                </div>
              </a>
            </div>
            <CardFooter sourceName={sourceName} articleUrl={articleUrl} />
          </div>
        </div>
      </Container>
    </VisibilitySensor>
  ) : (
    <VisibilitySensor onChange={onVisible} partialVisibility offset={{ top: 500, bottom: 500 }}>
      <Container>
        <div
          className={`card card-${small ? 'small' : 'normal'} ${
            visible ? `card-focused-${small ? 'small' : 'normal'}` : null
          }`}
        >
          <a href={articleUrl} target="_blank" rel="noreferrer">
            <div className="image" style={{ display: `${small ? 'none' : 'block'}` }}>
              <Image
                src={imageUrl}
                alt={title}
                width={500}
                height={200}
                style={{ objectFit: 'cover', maxWidth: '100%' }}
              />

              {pinned ? (
                <PinIcon>
                  <StarIcon fontSize="medium" />
                </PinIcon>
              ) : null}
            </div>
          </a>
          <div className="container">
            <div className="meta">
              {category ? (
                <>
                  <div className="category-container" onClick={() => handleCategoryClick(category)}>
                    <div className={`category type-${category}`}>
                      {category.replaceAll('-', ' ')}
                    </div>
                  </div>
                  <div className="divider">|</div>
                </>
              ) : null}
              <div className="time">{dayjs(pubTime).fromNow()}</div>
            </div>
            <div className="content">
              <a href={articleUrl} className="title" target="_blank" rel="noreferrer">
                {decode(title.replace(/  +/g, ' '))}
              </a>
              <div className="body">{decode(body.replace(/  +/g, ' '))}</div>
              <a href={articleUrl} target="_blank" rel="noreferrer">
                <div className="read-more">
                  <span>Pročitaj članak</span>
                  <ChevronRightIcon fontSize="small" />
                </div>
              </a>
            </div>
            <CardFooter sourceName={sourceName} articleUrl={articleUrl} title={title} />
          </div>
        </div>
      </Container>
    </VisibilitySensor>
  );
};

export default Card;
