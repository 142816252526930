import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 17;
  height: 30px;
  padding: 0 1rem;
  background: var(--lokalac-primary-2);
  color: var(--lokalac-text-light);

  animation: fadeIn ease-in-out 2s;

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
}

  .message {
    display: flex;

    .link {
      color: var(--lokalac-red);
      font-weight: 500;
      margin-left: 0.5rem;
    }
  }

  .remove-me {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 1.1rem;
    font-size: var(--lokalac-m);
    color: var(--lokalac-red);
    font-weight: 700;
    margin-left: 2rem;

    &:hover {
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 1024px) {
    .cookies-box {
      width: 100%;
      padding: 0 1rem;
    }
  }
`;
