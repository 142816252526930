import { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';

import { Store } from 'react-notifications-component';

import { FeedsContext } from '@/context/feeds.context';
import { UserModalContext } from '@/context/user-modal.context';
import { UserContext } from '@/context/user.context';

import {
  Footer,
  PersonalizeCategories,
  PersonalizeLocations,
  PersonalizeMap,
  PersonalizeFeeds,
} from '@/components';

import { Container } from './personalize.elements';

import AvTimerIcon from '@material-ui/icons/AvTimer';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { findCommonElements } from '@/utils/functions/helper.functions';

const Personalize = () => {
  const [newCategories, setNewCategories] = useState([]);
  const [newFeeds, setNewFeeds] = useState([]);
  const [newCounties, setNewCounties] = useState([]);
  const [newCities, setNewCities] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const { feeds } = useContext(FeedsContext);
  const { user, setUser } = useContext(UserContext);
  const { setShowUserModal } = useContext(UserModalContext);
  const [cookies, setCookie] = useCookies();
  const router = useRouter();

  useEffect(() => {
    for (let city in feeds.citiesAndFeeds) {
      const cityAndFeeds = feeds.citiesAndFeeds[city];

      if (findCommonElements(newFeeds, cityAndFeeds.feeds) === cityAndFeeds.feeds.length) {
        const index = newCities.indexOf(city);
        if (index === -1) {
          setNewCities((prev) => [...prev, city]);
        }
      } else if (findCommonElements(newFeeds, cityAndFeeds.feeds) < cityAndFeeds.feeds.length) {
        const index = newCities.indexOf(city);
        if (index !== -1) {
          const cityArray = [...newCities];
          cityArray.splice(index, 1);
          setNewCities(cityArray);
        }
      }
    }
  }, [newFeeds]);

  useEffect(() => {
    setNewCategories(cookies.categories);
    setNewFeeds(cookies.feeds);
  }, [cookies, feeds]);

  const savePreferences = () => {
    setIsUpdating(true);

    const token = window.localStorage.getItem('token');

    const preferences = {
      categories: newCategories,
      feeds: newFeeds,
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: token },
      body: JSON.stringify({
        userId: user.userId,
        preferences: JSON.stringify(preferences),
      }),
    };

    fetch('/api/v1/user/preferences', requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (!data.success) {
          setIsUpdating(400);
          setTimeout(() => setIsUpdating(false), 3000);
        } else {
          Store.addNotification({
            title: 'Izmjene su spremljene!',
            message: 'Idemo na tvoj feed.',
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });

          setUser({
            ...user,
            preferences: JSON.parse(data.preferences),
          });

          setCookie('categories', newCategories, { path: '/' });
          setCookie('feeds', newFeeds, { path: '/' });

          setTimeout(() => {
            router.push('/');
            setIsUpdating(false);
          }, 3000);
        }
      })
      .catch(console.log);
  };

  return (
    <Container>
      <div className="personalize-container">
        <div className="title-box">
          <div className="title">
            Odlično, krenimo s <span>personalizacijom!</span>
          </div>
        </div>
        <PersonalizeCategories newCategories={newCategories} setNewCategories={setNewCategories} />
        <PersonalizeLocations
          data={feeds}
          newFeeds={newFeeds}
          setNewFeeds={setNewFeeds}
          newCounties={newCounties}
          setNewCounties={setNewCounties}
          newCities={newCities}
          setNewCities={setNewCities}
        />
        <PersonalizeMap data={feeds} />
        <PersonalizeFeeds
          data={feeds}
          newFeeds={newFeeds}
          setNewFeeds={setNewFeeds}
          newCounties={newCounties}
          setNewCounties={setNewCounties}
          newCities={newCities}
          setNewCities={setNewCities}
        />
        <div className="save-box">
          <button
            className="save-button"
            onClick={() => {
              if (user.isAuthenticated) savePreferences();
              else setShowUserModal(true);
            }}
            disabled={isUpdating}
          >
            {isUpdating ? (
              <AvTimerIcon />
            ) : isUpdating === 200 ? (
              <DoneAllIcon />
            ) : isUpdating === 400 ? (
              <ErrorOutlineIcon />
            ) : (
              'Spremi izmjene'
            )}
          </button>
        </div>
        <div className="footer-box">
          <Footer />
        </div>
      </div>
    </Container>
  );
};

export default Personalize;
