import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(255, 255, 255, 0.7);
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;

  .modal-box {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    background: var(--lokalac-primary-2);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

    width: 400px;
    padding: 30px 30px 20px;

    .modal-close-x {
      position: absolute;
      top: 10px;
      right: 10px;

      &:hover {
        cursor: pointer;
      }
    }

    .title {
      font-size: var(--lokalac-xl);
      margin-bottom: 30px;
      padding: 0 40px;
    }

    .error-message {
      margin-bottom: 20px;
    }

    .or {
      display: flex;
      width: 100%;

      font-size: var(--lokalac-xs);
      margin: 10px 0;

      .line {
        border-bottom: 1px solid var(--lokalac-primary-1);
        margin: 7px 10px;
        width: 100%;
        height: 50%;
      }
    }

    .wave-emoji {
      font-size: var(--lokalac-xxl);
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--lokalac-xs);
      font-weight: 500;

      padding: 7px;
      height: 30px;
      width: 100%;
      border-radius: 20px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }

    .button-lokalac {
      background: var(--lokalac-primary-3);
      color: var(--lokalac-primary-1);
      font-weight: 700;

      &:hover {
        background: var(--lokalac-text-light);
        color: var(--lokalac-primary-2);
      }
    }

    .button-google,
    .button-facebook {
      color: var(--lokalac-text-light);
      border: 1px solid var(--lokalac-text-light);
      margin-bottom: 10px;

      &:hover {
        background: var(--lokalac-text-light);
        color: var(--lokalac-primary-2);
        border: 1px solid var(--lokalac-primary-2);
      }
    }

    .existing {
      margin-top: 10px;
      cursor: pointer;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      width: 240px;

      .form-control {
        width: 100%;
        display: flex;
        flex-direction: column;

        input {
          width: 100%;
          height: 26px;
          letter-spacing: 1px;
          padding: 1rem;
          border-radius: 5px;
          background: var(--lokalac-primary-3);
          color: var(--lokalac-black);
          border: 1px solid transparent;

          &::placeholder {
            font-size: var(--lokalac-xs);
            color: var(--lokalac-black);
          }

          &:focus {
            background: var(--lokalac-background);
            color: var(--lokalac-black);
            outline: none;
          }
        }

        small {
          display: flex;
          height: 15px;
          margin: 5px 0;
          font-size: var(--lokalac-xs);
          font-weight: 500;
          color: var(--lokalac-white);
        }

        .validation-success {
          border: 1px solid var(--lokalac-green);
        }

        .validation-error {
          border: 1px solid var(--lokalac-red);
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 240px;
      margin-bottom: 10px;

      .forgot-password {
        margin-top: 20px;
        font-weight: 500;
        font-size: var(--lokalac-m);
        cursor: pointer;
        border: 2px solid var(--lokalac-red);

        background: var(--lokalac-red);
        padding: 5px 20px;
        border-radius: 20px;
        width: 240px;
      }

      .new-account {
        margin-top: 20px;
        cursor: pointer;
        padding: 7px 20px;
        width: 280px;
      }

      button {
        border: none;
        &:disabled {
          border: none;
          cursor: default;
          opacity: 0.5;
        }
      }

      .terms {
        margin-bottom: 10px;

        a {
          text-decoration: underline;
        }

        input {
          cursor: pointer;
          margin-right: 5px;
        }
      }
    }
  }
`;
