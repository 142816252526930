import { Container } from './terms-of-use.elements';

const TermsOfUse = () => (
  <Container>
    <h1>UVJETI KORIŠTENJA</h1>
    <div className="subtitle">Uvjeti korištenja usluga i sadržaja</div>
    <h3>Prihvaćanje uvjeta korištenja</h3>
    <p>
      Dobro došli na portal Lokalac.hr koji omogućuje vam korištenje usluga i sadržaja s raznih
      internetskih stranica (u daljnjem tekstu: „Treća strana“) koje su regulirani ovim Uvjetima
      korištenja. Uvjeti korištenja primjenjuju se na sve sadržaje i usluge na domenama i
      poddomenama Lokalac.hr-a (u daljnjem tekstu: „Usluge i sadržaji“).
    </p>
    <p>
      Korištenjem Usluga i sadržaja smatra se da su korisnici u svakom trenutku upoznati s ovim
      Uvjetima korištenja, te da ih u potpunosti razumiju i prihvaćaju, uključujući i odredbe u vezi
      s kolačićima.
    </p>
    <h3>Odricanje od odgovornosti za sadržaj</h3>
    <p>
      Lokalac.hr ne kontrolira sadržaj koji se prikazuje domenama i poddomenama Lokalac.hr-a te ne
      može jamčiti točnost, porijeklo ili kvalitetu takvog sadržaja.{' '}
    </p>

    <p>
      Lokalac.hr neće ni na koji način biti odgovorna za materijalni ili nematerijalni gubitak ili
      štetu nastalu korištenjem Usluga i sadržaja. Radi osiguranja slobodnog i neometanog korištenja
      Usluga i sadržaja, ovi postupci su neprihvatljivi i strogo zabranjeni, te Lokalac.hr neće ni
      na koji način biti odgovoran za:
    </p>
    <ul>
      <li>
        objavljivanje, slanje i razmjena sadržaja koji krše postojeće hrvatske i/ili međunarodne
        zakone, sadržaj koji je uvredljiv, vulgaran, prijeteći, rasistički ili šovinistički te
        štetan na bilo koji drugi način,
      </li>
      <li>
        objavljivanje, slanje, razmjena i prenošenje informacija za koje Treća strana zna ili
        pretpostavlja da su lažne, a čije bi korištenje moglo nanijeti štetu korisnicima portala i
        usluga,
      </li>
      <li>lažno predstavljanje, odnosno predstavljanje u ime druge pravne ili fizičke osobe,</li>
      <li>
        manipuliranje identifikatorima kako bi se prikrilo porijeklo sadržaja koji Treća strana
        objavljuje ili šalje putem Usluga i sadržaja, objavljivanje, slanje i razmjena sadržaja koji
        su zaštićeni autorskim pravom,
      </li>
      <li>
        objavljivanje, slanje i razmjena neželjenih sadržaja korisnicima bez njihovog pristanka ili
        traženja, kao što su promotivni materijali, reklamne poruke, “spam”, lančana pisma,
        promotivni i reklamni materijali koji sadrže lažne informacije, zavaravajući navode ili
        tvrdnje prikupljanje, čuvanje i objavljivanje osobnih podataka korisnika.
      </li>
    </ul>
    <p>
      Lokalac.hr zadržava pravo na temelju vlastite procjene i bez prethodne obavijesti ukloniti
      sadržaj koji smatra neprikladnim ili koji nije u skladu s ovim Uvjetima korištenja.
    </p>
    <p>
      Lokalac.hr zadržava pravo ukidanja korisničkog računa bez prethodne najave u slučaju kršenja
      ovih Uvjeta korištenja. Prihvaćanjem ovih Uvjeta korištenja korisnik prihvaća i suglasan je da
      će Usluge i sadržaji uključivati reklame, raznorazne obavijesti, poruke administratora i
      slične poruke.
    </p>

    <h3>Autorska prava</h3>
    <p>
      Sadržaj Trećih strana na domenama i poddomena Lokalac.hr-a najvjerojatnije je zaštićen
      autorskim pravima. Mijenjanje, posuđivanje, prodavanje ili distribuiranje takvog sadržaja
      moguće je samo uz prethodnu dozvolu vlasnika sadržaja.
    </p>
    <p>
      Ukoliko pojedini korisnik smatra da je došlo do povrede autorskih prava na domenama i
      poddomena Lokalac.hr-a, o tome je dužan izvijestiti Lokalac.hr koristeći adresu:
      info@lokalac.hr.
    </p>

    <h3>Registracija korisnika i posjetitelja portala</h3>
    <p>
      Registracijom za Usluge i sadržaje korisnik dobiva podatke o korisničkom računu – korisničko
      ime i lozinku. Korisnik je dužan čuvati podatke o korisničkom računu, te je u potpunosti
      odgovoran za svu štetu nastalu neautoriziranim korištenjem svog korisničkog računa.
    </p>
    <p>
      Završetkom registracije korisnik prihvaća odgovornost za sve aktivnosti uzrokovane uporabom
      Usluga i sadržaja.
    </p>
    <p>
      Lokalac.hr nema pristup korisničkim lozinkama, tajnim pitanjima i odgovorima. Radi
      identifikacije prilikom pružanja pomoći korisnicima,
    </p>
    <p>Lokalac.hr može zatražiti e-mail adresu ili broj telefona korisnika.</p>

    <h3>Pomoć i podrška</h3>
    <p>Lokalac.hr korisnicima Portala i Usluga ne pruža telefonsku podršku.</p>
    <p>
      Pomoć i podrška dostupni su isključivo preko odgovarajuće e-mail adrese:{' '}
      <a href="mailto:info@lokalac.hr">info@lokalac.hr</a>
    </p>

    <h3>Zaštita privatnosti i tajnost podataka</h3>
    <p>
      Lokalac.hr poštuje privatnost svojih korisnika i posjetitelja Portala. Svi podaci iz procesa
      registracije te ostali podaci o korisniku koriste se isključivo u svrhu za koju su
      prikupljeni.
    </p>
    <p>
      Lokalac.hr ni pod kojim uvjetima neće iste učiniti dostupnima trećoj strani, osim u slučaju
      kada je takva obaveza regulirana zakonom.
    </p>
    <p>
      U svrhu boljeg korisničkog iskustva te kako bi se Usluge i sadržaji pravilno prikazivali i
      imali sve poztrebne funkcionalnosti koristimo kolačiće (cookies).
    </p>
    <p>
      Kolačić je informacija spremljena na vaše računalo od strane internetske stranice koju
      posjetite.
    </p>
    <p>Postavke kolačića mogu se kontrolirati i konfigurirati u vašem internet pregledniku.</p>
    <p>
      Korištenjem Usluga i sadržaja dajete svoj pristanak za spremanje kolačića (cookies) na vašem
      računalu.
    </p>

    <h3>Linkovi na druge internetske stranice</h3>
    <p>
      Većina linkova na domenama i poddomenama Lokalac.hr-a vodi na internetske stranice i/ili
      usluge koji nisu u vlasništvu niti pod kontrolom Lokalac.hr-a. Lokalac.hr nije odgovoran za
      sadržaj tih stranica.
    </p>

    <h3>Oglasi na Portalima</h3>
    <p>
      Pravila, uvjeti i načini oglašavanja na domenama i poddomenama Lokalac.hr-a uređena su
      općenitim uvjetima i pravilima digitalnog oglašavanja na području Republike Hrvatske.
    </p>

    <h3>Sadržaj portala</h3>
    <p>
      Stavovi izneseni u autorskim kolumnama i komentarima koji se prikazuju na stranicama Usluga i
      sadržaja, ne odražavaju stavove portala Lokalac.hr, već su to osobni stavovi autora pojedinih
      kolumni i komentara.
    </p>
    <p>
      Lokalac.hr nije vlasnik sadržaja koji objavljuju ili razmjenjuju korisnici Usluga i sadržaja i
      zadržava pravo koristiti neke od tih sadržaja u promotivne svrhe Usluga i sadržaja unutar
      kojih je sadržaj objavljen.
    </p>
    <p>Javno dostupan sadržaj je sav onaj sadržaj dostupan svim korisnicima Usluga i sadržaja.</p>
    <p>
      Svi samoinicijativno poslani materijali, a osobito fotografije, videouraci, tekstovi i drugi
      slični materijali, odnosno drugi autorski radovi (u daljnjem tekstu: Materijali) mogu, jednom
      ili više puta, bez vremenskog ograničenja, ali i ne moraju, biti objavljeni na domenama i
      poddomenama Lokalac.hr-a. Pošiljatelji Materijala prihvaćaju da za poslane Materijale neće
      biti isplaćen nikakav honorar. Sve osobe koje samoinicijativno pošalju Materijal jamče pod
      punom kaznenom i materijalnom odgovornošću da isti predstavlja isključivo njihovo vlasništvo
      te da na isti polažu autorska prava. Lokalac.hr poštuje intelektualno vlasništvo i autorska
      prava drugih, te na toj osnovi isto traži od svih onih s kojima stupi u bilo kakav odnos u
      pogledu Materijala koji su zaštićeni pozitivno-pravnom regulativom kojom je regulirana zaštita
      autorskih prava i intelektualnog vlasništva te se samoinicijativnim slanjem Materijala
      iskazuje pod punom materijalnom i kaznenom odgovornošću suglasnost s prije navedenim
      stajalištima.
    </p>

    <h3>Promjene sadržaja Portala i Usluga</h3>
    <p>
      Lokalac.hr zadržava pravo promjene, ukidanja (privremenog i trajnog) bilo kojeg sadržaja na
      Uslugama i sadržajima bez obaveze prethodne najave. Lokalac.hr nije odgovorna za bilo kakvu
      štetu nastalu tim promjenama.
    </p>

    <h3>Ukidanje i zabrana korištenja Usluga i sadržaja</h3>
    <p>
      Lokalac.hr zadržava pravo ukinuti ili uskratiti mogućnost korištenja vašeg korisničkog računa
      i/ili jedne ili više usluga za koju ste registrirani, a bez prethodne najave i obrazloženja.
    </p>
    <p>Lokalac.hr ne snosi odgovornost za bilo kakvu štetu nastalu ukidanjem korisničkog računa.</p>

    <h3>Promjene Uvjeta korištenja</h3>
    <p>
      Lokalac.hr zadržava pravo promjene Uvjeta korištenja u bilo kojem trenutku te neće biti
      odgovorna za moguće posljedice koje su proizašle iz takvih promjena. Promjene stupaju na snagu
      objavljivanjem na domenama i poddomenama Lokalac.hr-a.
    </p>
  </Container>
);

export default TermsOfUse;
