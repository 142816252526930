import { useId } from 'react';

import Collapsible from 'react-collapsible';

const CollapsibleWrapper = (props) => {
  const id = useId();

  return (
    <Collapsible
      {...props}
      contentElementId={`collapsible-content-${id}`}
      triggerElementProps={{
        ...props.triggerElementProps,
        id: `collapsible-trigger-${id}`,
      }}
    />
  );
};

export default CollapsibleWrapper;
