export const checkEmail = (input) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return input ? input.length > 0 && re.test(input.trim()) : null;
};

export const checkAlpha = (input) => {
  const re = /^[a-zA-ZČčĆćšŠđĐžŽ\- -]*$/;

  return input ? input.length > 0 && re.test(input.trim()) : null;
};

export const checkAlphaNumeric = (input) => {
  const re = /^[0-9a-zA-Z\-_-]*$/;

  return input ? input.length > 0 && re.test(input.trim()) : null;
};
