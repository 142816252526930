import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid var(--lokalac-primary-1);

  .title {
    font-size: var(--lokalac-l);

    span {
      font-weight: 500;
    }
  }

  .categories {
    display: flex;
    padding: 20px 0;

    .col-1 {
      .form-control {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .col-2 {
      font-weight: 500;
      margin: 6px 20px 0;
    }

    .col-3 {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .form-control {
        display: flex;
        align-items: center;
        margin-right: 30px;
        margin-bottom: 10px;
        cursor: pointer;
        width: 230px;

        input {
          margin-right: 10px;
          cursor: pointer;
        }

        .secondary-option {
          display: flex;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: 500;
          font-size: var(--lokalac-xs);
          align-items: center;
          color: var(--lokalac-text-light);

          user-select: none;
          padding: 3px 8px;
          border-radius: 15px;
          margin: 3px 0;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
  }
  @media only screen and (max-width: 650px) {
    .categories {
      display: flex;
      flex-direction: column;

      .col-1,
      .col-2 {
        margin-bottom: 20px;
      }
    }
  }
  @media only screen and (max-width: 425px) {
  }
`;
