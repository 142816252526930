import styled from 'styled-components';

export const Container = styled.div`
  .feed {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }

  .feed-less-padding {
    padding: 20px;
  }

  .feed-more-padding {
    padding: 20px 80px;
  }

  @media only screen and (max-width: 1200px) {
    .feed {
      margin-top: 20px;
    }

    .feed-more-padding {
      padding: 30px 10px;
    }

    .feed-less-padding {
      padding: 10px;
    }
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    max-width: 1430px;
    margin: 0 auto;

    padding-bottom: 40px;
  }
`;
