import { useEffect } from 'react';

import { CookiesPolicy, TermsOfUse, PrivacyPolicy, FAQ } from '@/components';

import { Container } from './support.elements';

const Support = ({ type }) => {
  useEffect(() => window.scrollTo(0, 0));
  const closeTab = () => window.close();

  return (
    <Container>
      <div className="top-row">
        <div className="disclaimers-button">
          <div className="button" onClick={closeTab}>
            POVRATAK
          </div>
        </div>
      </div>
      {type === 'termsOfUse' ? (
        <TermsOfUse />
      ) : type === 'privacyPolicy' ? (
        <PrivacyPolicy />
      ) : type === 'FAQ' ? (
        <FAQ />
      ) : type === 'cookiesPolicy' ? (
        <CookiesPolicy />
      ) : (
        ''
      )}
    </Container>
  );
};

export default Support;
