import { useState, useContext } from 'react';
import Link from 'next/link';
import { useCookies } from 'react-cookie';

import { UserModalContext } from '@/context/user-modal.context';
import { UserContext } from '@/context/user.context';

import { checkAlpha, checkEmail } from '@/utils/functions/validation.functions';

import CloseIcon from '@material-ui/icons/Close';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const UserRegister = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [authMessage, setAuthMessage] = useState('');
  const { setShowUserModal } = useContext(UserModalContext);
  const { setUser } = useContext(UserContext);
  const [cookies, setCookie] = useCookies();

  const allowSignUp =
    password.length > 5 &&
    checkAlpha(firstName) &&
    checkAlpha(lastName) &&
    checkEmail(email) &&
    terms;

  const handleSignUp = (e) => {
    e.preventDefault();
    setIsUpdating(true);
    createUser();
  };

  const saveAuthTokenInSession = (token) => window.localStorage.setItem('token', token);

  const createUser = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        password,
        preferences: {
          categories: cookies.categories,
          feeds: cookies.feeds,
        },
      }),
    };

    fetch('/api/v1/user/signUp', requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (!data.success) {
          setIsUpdating(400);
          setAuthMessage(data.error);
          setTimeout(() => setIsUpdating(false), 3000);
        } else {
          setIsUpdating(200);
          setTimeout(() => setIsUpdating(false), 3000);
          saveAuthTokenInSession(data.token);

          const { email, firstName, lastName, userId, preferences: dbPreferences } = data.user;
          const preferences = JSON.parse(dbPreferences);

          setUser({
            ready: true,
            isAuthenticated: true,
            userId,
            email,
            firstName,
            lastName,
            preferences,
          });
          setShowUserModal(false);
          setCookie('userLoggedIn', true, { path: '/' });
        }
      })
      .catch(console.log);
  };

  return (
    <form className="modal-box login-box">
      <CloseIcon fontSize="medium" className="modal-close-x" />
      <div className="title">Registriraj se</div>
      <div className="inputs">
        <div className="form-control">
          <input
            type="text"
            name="firstName"
            id="firstName"
            autoFocus
            placeholder="Ime"
            onChange={(e) => setFirstName(e.target.value)}
            className={
              firstName.length === 0
                ? null
                : checkAlpha(firstName)
                ? 'validation-success'
                : 'validation-error'
            }
          />
          <small>
            {firstName.length > 0 && !checkAlpha(firstName) ? 'Molimo unesite valjano ime.' : null}
          </small>
        </div>
        <div className="form-control">
          <input
            type="text"
            name="lastName"
            id="lastName"
            placeholder="Prezime"
            onChange={(e) => setLastName(e.target.value)}
            className={
              lastName.length === 0
                ? null
                : checkAlpha(lastName)
                ? 'validation-success'
                : 'validation-error'
            }
          />
          <small>
            {lastName.length > 0 && !checkAlpha(lastName)
              ? 'Molimo unesite valjano prezime.'
              : null}
          </small>
        </div>
        <div className="form-control">
          <input
            type="email"
            name="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            className={
              authMessage === 'Email taken'
                ? 'validation-error'
                : email.length === 0
                ? null
                : checkEmail(email)
                ? 'validation-success'
                : 'validation-error'
            }
          />
          <small>
            {email.length > 0 && !checkEmail(email) ? 'Molimo unesite valjanu email adresu.' : null}
            {authMessage === 'Email taken'
              ? 'Navedena email adresa se već koristi.'
              : authMessage.length > 0 && authMessage === 'Recaptcha fail'
              ? 'Recaptcha provjera nije uspijela.'
              : null}
          </small>
        </div>
        <div className="form-control">
          <input
            type="password"
            name="password"
            placeholder="Lozinka"
            onChange={(e) => setPassword(e.target.value)}
            className={
              password.length === 0
                ? null
                : password.length > 5
                ? 'validation-success'
                : 'validation-error'
            }
          />
          <small>
            {password.length > 0 && password.length < 6 ? 'Unesite minimalno 6 znakova.' : null}
          </small>
        </div>
      </div>
      <div className="buttons">
        <div className="terms">
          <input
            type="checkbox"
            name="termsOfUse"
            id="termsOfUse"
            className="terms-checkbox"
            onChange={() => setTerms(!terms)}
            value={terms}
          />
          <span>
            Pristajem na{' '}
            <Link href="/uvjeti-koristenja" target="_blank">
              Uvjete korištenja
            </Link>
            .
          </span>
        </div>
        <button
          className="button button-lokalac"
          type="submit"
          onClick={handleSignUp}
          disabled={!allowSignUp || isUpdating}
        >
          {isUpdating ? (
            <AvTimerIcon />
          ) : isUpdating === 200 ? (
            <DoneAllIcon />
          ) : isUpdating === 400 ? (
            <ErrorOutlineIcon />
          ) : (
            'Registriraj se'
          )}
        </button>
      </div>
    </form>
  );
};

export default UserRegister;
