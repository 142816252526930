import { useState, useEffect, useContext } from 'react';

import { FeedsContext } from '@/context/feeds.context';

import { CollapsibleWrapper } from '@/components';

import { Container } from './personalize-location.elements';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { findCommonElements } from '@/utils/functions/helper.functions';

const PersonalizeLocation = ({
  county,
  countyCities,
  newFeeds,
  setNewFeeds,
  newCounties,
  setNewCounties,
  newCities,
  setNewCities,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [collapsed2, setCollapsed2] = useState(true);
  const { feeds } = useContext(FeedsContext);

  useEffect(() => {
    if (
      findCommonElements(newCities, feeds.countiesAndCities[county].cities) ===
      feeds.countiesAndCities[county].cities.length
    ) {
      const index = newCounties.indexOf(county);
      if (index === -1) {
        setNewCounties((prev) => [...prev, county]);
      }
    } else if (
      findCommonElements(newCities, feeds.countiesAndCities[county].cities) <
      feeds.countiesAndCities[county].cities.length
    ) {
      const index = newCounties.indexOf(county);
      if (index !== -1) {
        const countyArray = [...newCounties];
        countyArray.splice(index, 1);
        setNewCounties(countyArray);
      }
    }
  }, [newCities]);

  const handleCountyChange = (county) => {
    const index = newCounties.indexOf(county);

    if (index === -1) {
      setNewCounties((prev) => [...prev, county]);

      feeds.countiesAndCities[county].cities.forEach((city) => {
        const index = newCities.indexOf(city);
        if (index === -1) {
          setNewCities((prev) => [...prev, city]);
        }
      });

      feeds.countiesAndFeeds[county].feeds.forEach((feed) => {
        const index = newFeeds.indexOf(feed);
        if (index === -1) {
          setNewFeeds((prev) => [...prev, feed]);
        }
      });
    } else {
      const countyArray = [...newCounties];
      countyArray.splice(index, 1);
      setNewCounties(countyArray);

      const cityArray = newCities.filter(
        (city) => !feeds.countiesAndCities[county].cities.includes(city)
      );
      setNewCities(cityArray);

      const feedArray = newFeeds.filter(
        (feed) => !feeds.countiesAndFeeds[county].feeds.includes(feed)
      );
      setNewFeeds(feedArray);
    }
  };

  const handleCityChange = (city, county) => {
    const index = newCities.indexOf(city);

    if (index === -1) {
      setNewCities((prev) => [...prev, city]);

      feeds.citiesAndFeeds[city].feeds.forEach((feed) => {
        const index = newFeeds.indexOf(feed);
        if (index === -1) {
          setNewFeeds((prev) => [...prev, feed]);
        }
      });
    } else {
      const cityArray = [...newCities];
      cityArray.splice(index, 1);
      setNewCities(cityArray);

      const feedArray = newFeeds.filter((feed) => !feeds.citiesAndFeeds[city].feeds.includes(feed));
      setNewFeeds(feedArray);
    }
  };

  return (
    <Container>
      <div className="county" onClick={() => setCollapsed(!collapsed)}>
        <input
          type="checkbox"
          name="county"
          id={county}
          checked={newCounties.includes(county)}
          onChange={() => handleCountyChange(county)}
        />
        <span>{county}</span>
        {collapsed ? <ExpandMoreIcon fontSize="small" /> : <ExpandLessIcon fontSize="small" />}
      </div>
      <CollapsibleWrapper open={!collapsed}>
        <div className="options">
          {/* <div className="option-1">
            <input type="checkbox" name="all-feeds" id="all-feeds" />
            <div>uključi portale koji pokrivaju čitavu županiju</div>
          </div> */}
          <div className="option-2">
            <div className="option" onClick={() => setCollapsed2(!collapsed2)}>
              {collapsed2 ? (
                <ExpandMoreIcon fontSize="small" />
              ) : (
                <ExpandLessIcon fontSize="small" />
              )}
              <div>odaberi portale koji pokrivaju grad/mjesto</div>
            </div>
            <CollapsibleWrapper open={!collapsed2}>
              <div className="cities">
                {countyCities.map((city, i) => (
                  <div className="city" key={i}>
                    <input
                      type="checkbox"
                      name="city"
                      id={city}
                      checked={newCities.includes(city)}
                      onChange={() => handleCityChange(city, county)}
                    />
                    <span onClick={() => handleCityChange(city, county)}>{city}</span>
                  </div>
                ))}
              </div>
            </CollapsibleWrapper>
          </div>
        </div>
      </CollapsibleWrapper>
    </Container>
  );
};

export default PersonalizeLocation;
