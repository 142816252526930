const categories = [
  { name: 'Sve', slug: 'sve' },
  { name: 'Vijesti', slug: 'vijesti' },
  { name: 'Sport', slug: 'sport' },
  { name: 'Pravo i pravda', slug: 'pravo-i-pravda' },
  { name: 'Politika', slug: 'politika' },
  { name: 'Sukobi', slug: 'sukobi' },
  { name: 'Incidenti', slug: 'incidenti' },
  { name: 'Vrijeme', slug: 'vrijeme' },
  { name: 'Ekonomija i biznis', slug: 'ekonomija-i-biznis' },
  { name: 'Okoliš', slug: 'okoliš' },
  { name: 'Religija', slug: 'religija' },
  { name: 'Zdravlje', slug: 'zdravlje' },
  { name: 'Lifestyle', slug: 'lifestyle' },
  { name: 'Edukacija', slug: 'edukacija' },
  { name: 'Kultura', slug: 'kultura' },
  { name: 'Znanost i tehnologija', slug: 'znanost-i-tehnologija' },
  { name: 'Posao', slug: 'posao' },
];

export { categories };
