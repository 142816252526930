import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  width: 100%;

  padding: 20px 0 10px;

  background: linear-gradient(to bottom, var(--lokalac-primary-1) 0%, var(--lokalac-primary-2) 99%);
  color: var(--lokalac-text-light);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  user-select: none;

  @media screen and (max-width: 768px) {
    height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);

    overflow-y: auto;
  }
`;

export const LocationsContainer = styled.div`
  display: flex;

  overflow: auto;
  height: 100%;
  padding: 0 5%;
`;

export const LocationList = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin: 0 auto;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const LocationListItem = styled.div`
  display: flex;

  width: 25%;
  max-width: 250px;
  margin-bottom: 15px;

  @media screen and (max-width: 1024px) {
    width: 33%;
  }

  @media screen and (max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  width: 95%;
  margin-top: 10px;
  padding-top: 10px;
  position: relative;

  border-top: 2px solid var(--lokalac-primary-1);
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--lokalac-primary-3);
  color: var(--lokalac-primary-1);
  font-weight: 600;
  outline: 0;
  border: 0;
  margin: 0 20px;
  padding: 10px 20px;
  min-width: 120px;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background: var(--lokalac-text-light);
    color: var(--lokalac-primary-2);
  }
`;

export const CloseMe = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 20px;

  > span {
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 1400px) {
    display: none;
  }
`;
