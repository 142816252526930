import { Fragment } from 'react';
import Image from 'next/image';
import dayjs from 'dayjs';
import 'dayjs/locale/hr';
import escapeHTML from 'escape-html';
import { Text } from 'slate';

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import {
  Container,
  Title,
  Meta,
  Author,
  Published,
  Content,
  FeaturedImage,
  Social,
  Caption,
  ImageWrap,
} from './post.elements';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailIcon from '@material-ui/icons/Mail';

const serialize = (children) =>
  children.map((node, i) => {
    if (Text.isText(node)) {
      let text = <span dangerouslySetInnerHTML={{ __html: escapeHTML(node.text) }} />;

      if (node.bold) {
        text = <strong key={i}>{text}</strong>;
      }

      if (node.code) {
        text = <code key={i}>{text}</code>;
      }

      if (node.italic) {
        text = <em key={i}>{text}</em>;
      }

      if (node.strikethrough) {
        text = <del key={i}>{text}</del>;
      }

      if (node.underline) {
        text = <u key={i}>{text}</u>;
      }

      return <Fragment key={i}>{text}</Fragment>;
    }

    if (!node) {
      return null;
    }

    switch (node.type) {
      case 'h1':
        return <h1 key={i}>{serialize(node.children)}</h1>;
      case 'h2':
        return <h2 key={i}>{serialize(node.children)}</h2>;
      case 'h3':
        return <h3 key={i}>{serialize(node.children)}</h3>;
      case 'h4':
        return <h4 key={i}>{serialize(node.children)}</h4>;
      case 'h5':
        return <h5 key={i}>{serialize(node.children)}</h5>;
      case 'h6':
        return <h6 key={i}>{serialize(node.children)}</h6>;
      case 'quote':
        return <blockquote key={i}>{serialize(node.children)}</blockquote>;
      case 'ul':
        return <ul key={i}>{serialize(node.children)}</ul>;
      case 'ol':
        return <ol key={i}>{serialize(node.children)}</ol>;
      case 'li':
        return <li key={i}>{serialize(node.children)}</li>;
      case 'link':
        return (
          <a href={escapeHTML(node.url)} key={i}>
            {serialize(node.children)}
          </a>
        );
      case 'upload':
        return (
          <ImageWrap key={i}>
            <Image
              src={node.value.url}
              width={1024}
              height={512}
              alt={node.value.alt}
              style={{ objectFit: 'cover', maxWidth: '100%', maxHeight: '100%' }}
            />
            {node.value.caption ? <Caption>{node.value.caption}</Caption> : null}
          </ImageWrap>
        );

      default:
        return <p key={i}>{serialize(node.children)}</p>;
    }
  });

const Post = ({ title, content, author, imgUrl, imgCaption, imgAlt, createdAt, articleUrl }) => (
  <Container>
    <Title>{title}</Title>
    <Meta>
      <div>
        <Author>{author}</Author>
        <Published>
          Lokalac • {dayjs(createdAt).locale('hr').format('DD. MMM YYYY. HH:mm')}
        </Published>
      </div>
      <div>
        <Social>
          <div className="icon">
            <EmailShareButton url={articleUrl} subject={title}>
              <MailIcon fontSize="medium" />
            </EmailShareButton>
          </div>
          <div className="icon">
            <FacebookShareButton url={articleUrl} quote={title}>
              <FacebookIcon fontSize="medium" />
            </FacebookShareButton>
          </div>
          <div className="icon">
            <TwitterShareButton url={articleUrl} title={title}>
              <TwitterIcon fontSize="medium" />
            </TwitterShareButton>
          </div>
          <div className="icon">
            <WhatsappShareButton url={articleUrl} title={title}>
              <WhatsAppIcon fontSize="medium" />
            </WhatsappShareButton>
          </div>
        </Social>
      </div>
    </Meta>
    <FeaturedImage>
      <Image
        src={imgUrl}
        width={1024}
        height={512}
        alt={imgAlt}
        style={{ objectFit: 'cover', maxWidth: '100%', maxHeight: '100%' }}
      />
      <Caption>{imgCaption}</Caption>
    </FeaturedImage>
    <Content>{serialize(content)}</Content>
    <div id="midasWidget__10560"></div>
    <Social $bottom>
      <div className="icon">
        <EmailShareButton url={articleUrl} subject={title}>
          <MailIcon fontSize="medium" />
        </EmailShareButton>
      </div>
      <div className="icon">
        <FacebookShareButton url={articleUrl} quote={title}>
          <FacebookIcon fontSize="medium" />
        </FacebookShareButton>
      </div>
      <div className="icon">
        <TwitterShareButton url={articleUrl} title={title}>
          <TwitterIcon fontSize="medium" />
        </TwitterShareButton>
      </div>
      <div className="icon">
        <WhatsappShareButton url={articleUrl} title={title}>
          <WhatsAppIcon fontSize="medium" />
        </WhatsappShareButton>
      </div>
    </Social>
    <hr />
  </Container>
);

export default Post;
