import Link from 'next/link';

import { Container } from './footer.elements';

const Footer = () => (
  <Container>
    <Link href="/politika-privatnosti" target="_blank" className="footer-item">
      Politika Privatnosti
    </Link>
    <div className="divider">|</div>
    <Link href="/uvjeti-koristenja" target="_blank" className="footer-item">
      Uvjeti Korištenja
    </Link>
    <div className="divider">|</div>
    <Link href="/politika-kolacica" target="_blank" className="footer-item">
      Kolačići
    </Link>
  </Container>
);

export default Footer;
