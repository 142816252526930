import { PersonalizeFeed } from '@/components';

import { Container } from './personalize-feeds.elements';

const PersonalizeFeeds = ({
  data,
  newFeeds,
  setNewFeeds,
  newCounties,
  setNewCounties,
  setNewCities,
}) => {
  const sortedCounties = data?.countiesAndCities ? Object.keys(data?.countiesAndCities).sort() : [];

  const handleChange = () => {
    if (data.feeds.length !== newFeeds.length) {
      setNewFeeds(data.feeds);
      setNewCounties(data.counties);
      setNewCities(data.cities);
    } else {
      setNewFeeds([]);
      setNewCounties([]);
      setNewCities([]);
    }
  };

  return data ? (
    <Container>
      <div className="title">
        S kojih <span>portala</span> želiš primati vijesti?
      </div>
      <div className="select-all-row">
        <div className="select-all-feeds">
          <div className="primary-option" onClick={handleChange}>
            <input
              type="checkbox"
              name="all-feeds"
              id="all-feeds"
              checked={data?.feeds?.length === newFeeds.length}
              onChange={handleChange}
            />
            označi sve portale
          </div>
        </div>
        <div className="warning">
          <span>UPOZORENJE!</span>
          <span>
            Ovo će poništiti trenutni odabir portala prema odabranim lokacijama interesa definiranim
            u prošlom koraku.
          </span>
        </div>
      </div>
      <div className="message-box">
        Trenutno označeni portali na osnovi odabranih željenih županija/gradova:
      </div>
      <div className="feeds">
        {sortedCounties.map((county, i) => (
          <PersonalizeFeed
            key={i}
            county={county}
            countiesAndFeeds={data?.countiesAndFeeds}
            newCounties={newCounties}
            setNewCounties={setNewCounties}
            newFeeds={newFeeds}
            setNewFeeds={setNewFeeds}
          />
        ))}
      </div>
    </Container>
  ) : null;
};

export default PersonalizeFeeds;
