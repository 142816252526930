import Head from 'next/head';

import { head } from '@/data/head.data';

const MetaDecorator = ({ location }) => {
  const hostname = process.env.DOMAIN;
  const pathName = decodeURI(location.asPath.replace(/\/$/, '')).split('?').at(0);

  return (
    <Head>
      <title>
        {`
Lokalac.hr | ${
          pathName === '/' || pathName === ''
            ? head.root.title
            : head.hasOwnProperty(pathName.substring(1))
            ? head[pathName.substring(1)]?.title
            : 'Stranica nije pronađena'
        }
`}
      </title>
      <meta
        name="description"
        content={
          pathName === '/' || pathName === ''
            ? head.root.description
            : head.hasOwnProperty(pathName.substring(1))
            ? head[pathName.substring(1)]?.description
            : ''
        }
      />
      <meta
        name="og:description"
        content={
          pathName === '/' || pathName === ''
            ? head.root.description
            : head.hasOwnProperty(pathName.substring(1))
            ? head[pathName.substring(1)]?.description
            : ''
        }
      />
      <meta charSet="utf-8" />
      <meta property="og:image" content={hostname + '/lokalac-logo-blue.jpg'} />
      <meta property="og:url" content={hostname + pathName} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:site_name" content="Lokalac" />
      <meta name="twitter:image:alt" content="Lokalac logo" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" key="viewport" />
      <meta name="theme-color" content="#0f7ec5" />
      {head.hasOwnProperty(pathName.substring(1)) && head[pathName.substring(1)]?.noIndex ? (
        <meta name="robots" content="noindex" />
      ) : null}
      <link rel="manifest" href="/manifest.json" crossOrigin="use-credentials" />
      <link rel="apple-touch-icon" href="/favicon.png"></link>
      <link rel="icon" href="/favicon.png" />
    </Head>
  );
};

export default MetaDecorator;
