import { Container } from './privacy-policy.elements';

const PrivacyPolicy = () => (
  <Container>
    <h1>POLITIKA PRIVATNOSTI</h1>
    <p>
      LOKALAC.HR posvećuje veliku pozornost zaštiti osobnih podataka naših korisnika. Osobne podatke
      korisnika nikad ne skupljamo niti obrađujemo, osim ako ih korisnici nisu eksplicitno učinili
      dostupnima. Obrada osobnih podataka temelji se na jednoj od zakonitih osnova obrade.
    </p>
    <p>
      Osobnim podacima smatraju se podaci kojima se korisnici mogu identificirati, poput imena,
      spola, adrese, e-maila ili poštanske adrese i drugog. LOKALAC.HR ne prikuplja Vaše osobne
      podatke osim u slučaju kada se ih eksplicitno učinili dostupnima, čime pristajete odnosno
      dajete privolu na njihovo korištenje u niže navedene svrhe (članak 6. stavak 1. Opće uredbe o
      zaštiti podataka).
    </p>
    <p>
      LOKALAC.HR će koristiti Vaše osobne podatke za potrebe tehničkog upravljanja internetskim
      stranicama, analize te dostave općih i individualiziranih ponuda, s ciljem da Vam se omogući
      pristup posebnim informacijama te za potrebe komunikacije.
    </p>
    <p>
      LOKALAC.HR na portalu koristi tehnologiju takozvanih internetskih cookiesa (hrvatski:
      kolačića). Tehnologiju cookiesa koristimo kako bismo mogli pružati našu online uslugu,
      analizirati korištenje sadržaja, nuditi oglašivačka rješenja, kao i za ostale funkcionalnosti
      koje ne bismo mogli pružati bez korištenja cookiesa.
    </p>
    <p>
      Detaljan popis cookiesa potražite{' '}
      <a href="/politika-kolacica" target="_blank">
        ovdje
      </a>
      . Podaci pohranjeni korištenjem cookiesa ne sadrže osobne detalje iz kojih bi se mogao
      utvrditi identitet korisnika. Suglasnost za cookiese možete povući kada god želite. Cookiese
      možete obrisati ili blokirati kroz svoj internetski preglednik ili koristeći specijalizirane
      softvere za to.
    </p>
    <p>
      Napominjemo kako onemogućavanje svih cookiesa može uzrokovati probleme u funkcioniranju većine
      stranica, pa tako i portala Lokalac.hr.
    </p>
    <p>
      Napominjemo kako se neki podaci, poput vrste preglednika koji koristite, broja posjeta,
      prosječnog vremena provedenog na stranicama, pregledavani sadržaj i slični obrađuju automatski
      prilikom pristupa portalu Lokalac.hr. Navedeni podaci se koriste u svrhu unaprijeđenja
      funkcionalnosti naših stranica.
    </p>
    <p>
      Napominjemo kako korisnik ima pravo da se na njega ne odnosi odluka koja se temelji isključivo
      na automatiziranoj obradi, osim ako je takva odluka potrebna za sklapanje ili izvršenje
      ugovora između korisnika i LOKALAC.HR dopuštena hrvatskim pravom ili pravom Unije ili
      temeljena na izričitoj privoli korisnika.
    </p>
    <p>
      Ukoliko ste dali izričitu privolu za primanje newslettera, možemo Vam poslati elektroničke
      poruke s marketinškim sadržajem. Podatke koje ste nam dostavili kako biste se pretplatili na
      newsletter koristit će se samo za slanje newslettera. Ukoliko želite odustati od pretplate na
      Newsletter, to možete učiniti u bilo kojem trenutku putem opcije otkazivanja pretplate koja se
      nalazi u newsletteru.
    </p>
    <p>
      Obrada podataka za primitak elektroničkih poruka nužna je za potrebe naših legitimnih
      interesa. Stoga niste obvezni dostaviti te podataka i/ili privolu na primitak biltena. Pravna
      osnova za primanje newslettera je Vaša privola (članak 6. stavak 1. Opće uredbe o zaštiti
      podataka). U slučaju da niste dali privolu newsletter vam neće biti poslan.
    </p>
    <p>
      Korisnik odlučuje koje podatke o sebi čini dostupnima. U slučaju promjene bilo kojeg Vašeg
      osobnog podatka, ili ako želite da podaci koje imamo o Vama budu izbrisani, molimo Vas da o
      promjenama obavijestite na e-mail:{' '}
      <a href="mailto:privatnost@lokalac.hr">privatnost@lokalac.hr</a> kako bismo ispravili,
      ažurirali ili izbrisali Vaše osobne podatke.
    </p>
    <p>
      Ovim putem Vas obavještavamo kako imate pravo povući danu privolu u bilo kojem trenutku u
      cijelosti ili djelomično, na način da se obavijesti na e-mail adresu:{' '}
      <a href="mailto:privatnost@lokalac.hr">privatnost@lokalac.hr</a> u pisanom ili elektroničkom
      obliku ili usmenim putem.
    </p>
    <p>
      Nadalje, da po primitku izjave o povlačenju privole, pisanim putem ćemo potvrditi primitak, a
      osobni podaci koji su obuhvaćeni izjavom o povlačenju se više neće obrađivati počevši od
      datuma povlačenja privole. Sve obrade i/ili prijenosi učinjeni do datuma povlačenja privole
      također će biti izbrisani.
    </p>
    <br />
    <p>Službenik za zaštitu podataka:</p>
    <p>
      <a href="mailto:privatnost@lokalac.hr">privatnost@lokalac.hr</a>
    </p>
  </Container>
);

export default PrivacyPolicy;
