import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 1024px;
  width: 100%;
  padding: 80px;
  margin: 0 auto;

  @media only screen and (max-width: 1440px) {
    padding: 40px 20px;
  }

  @media only screen and (max-width: 500px) {
    padding: 40px 20px;
  }
`;

export const Title = styled.h1`
  font-size: 3rem;

  @media only screen and (max-width: 1440px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 500px) {
    font-size: 2rem;
  }
`;

export const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 20px;

  font-size: 15px;
  color: var(--lokalac-gray-2);
`;

export const Author = styled.div``;

export const Published = styled.div``;

export const FeaturedImage = styled.div`
  margin: 40px 0;

  @media only screen and (max-width: 500px) {
    height: 200px;
  }
`;

export const ImageWrap = styled.div`
  margin: 20px 0;

  @media only screen and (max-width: 500px) {
    height: 200px;
  }
`;

export const Caption = styled.div`
  color: var(--lokalac-gray-2);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 2.2rem;
    font-weight: 500;

    margin: 15px 0 10px;
  }
  h2 {
    font-size: 2rem;
    font-weight: 500;

    margin: 15px 0 10px;
  }
  h3 {
    font-size: 1.8rem;
    font-weight: 500;

    margin: 15px 0 10px;
  }
  h4 {
    font-size: 1.6rem;
    font-weight: 500;

    margin: 15px 0 10px;
  }
  h5 {
    font-size: 1.4rem;
    font-weight: 500;

    margin: 15px 0 10px;
  }
  h6 {
    font-size: 1.2rem;
    font-weight: 500;

    margin: 15px 0 10px;
  }
  blockquote {
  }
  ul {
  }
  ol {
  }
  li {
  }
  a {
    & * {
      text-decoration: underline;
      color: var(--lokalac-primary-2);
    }
  }
  p {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    margin: 5px 0;
  }
  code {
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid var(--lokalac-primary-1);
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
  }
`;

export const Social = styled.div`
  display: flex;

  margin: ${({ $bottom }) => $bottom && '40px 0 20px'};
  gap: 10px;

  font-size: var(--lokalac-s);
  color: var(--lokalac-gray-2);

  transition: all 0.2s ease-in-out;

  .icon {
    &:hover {
      cursor: pointer;
      color: var(--lokalac-primary-2);
    }
  }
`;
