import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';

import TagManager from 'react-gtm-module';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import { MantineProvider } from '@mantine/core';
import { UserProvider } from '@/context/user.context';
import { UserModalProvider } from '@/context/user-modal.context';
import { FeedsProvider } from '@/context/feeds.context';

import { CookiesConsent, MetaDecorator } from '@/components';

import GlobalStyle from '@/styles/global.styles';
import '@/styles/global.styles.scss';

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {
  const router = useRouter();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') TagManager.initialize({ gtmId: 'GTM-T8K2J87' });
  }, []);

  return (
    <>
      <MetaDecorator location={router} />
      <ReactNotifications />
      <GlobalStyle />
      <FeedsProvider>
        <SessionProvider session={session}>
          <UserProvider>
            <UserModalProvider>
              <MantineProvider>
                <Component {...pageProps} />
              </MantineProvider>
            </UserModalProvider>
          </UserProvider>
        </SessionProvider>
      </FeedsProvider>
      <CookiesConsent />
    </>
  );
};
export default MyApp;
