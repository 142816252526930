const ACTIONS = {
  SET_DATA: 'SET_DATA',
};

const feedsReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_DATA:
      return {
        ...state,
        counties: action.value.counties,
        cities: action.value.cities,
        feeds: action.value.feeds,
        countiesAndCities: action.value.countiesAndCities,
        countiesAndFeeds: action.value.countiesAndFeeds,
        citiesAndFeeds: action.value.citiesAndFeeds,
        feedsAndLocation: action.value.feedsAndLocation,
      };
    default:
      return state;
  }
};

export { feedsReducer, ACTIONS };
