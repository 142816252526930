import { useState, useContext } from 'react';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';
import { Store } from 'react-notifications-component';
import { useCookies } from 'react-cookie';

import { UserContext } from '@/context/user.context';
import { UserModalContext } from '@/context/user-modal.context';

import { checkEmail } from '@/utils/functions/validation.functions';

import CloseIcon from '@material-ui/icons/Close';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const UserLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [authMessage, setAuthMessage] = useState('');
  const { setUser } = useContext(UserContext);
  const { setShowUserModal, setUserModalType } = useContext(UserModalContext);
  const [cookies, setCookie] = useCookies();
  const router = useRouter();

  const allowSignIn = password.length > 5 && checkEmail(email);

  const saveAuthTokenInSession = (token) => window.localStorage.setItem('token', token);

  const getUser = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        password,
      }),
    };

    fetch('/api/v1/user/signIn', requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (!data.success) {
          setIsUpdating(400);
          setAuthMessage(data.error);
          setTimeout(() => setIsUpdating(false), 1000);
        } else {
          setIsUpdating(200);
          saveAuthTokenInSession(data.token);

          const { userId, email, firstName, lastName, preferences: dbPreferences, isAdmin } = data.user;
          const preferences = JSON.parse(dbPreferences);
          const categories = preferences.categories;
          const feeds = preferences.feeds;

          setUser({
            ready: true,
            isAuthenticated: true,
            userId,
            email,
            firstName,
            lastName,
            preferences,
            isAdmin
          });

          setCookie('categories', categories, { path: '/' });
          setCookie('feeds', feeds, { path: '/' });
          setCookie('userLoggedIn', true, { path: '/' });

          setShowUserModal(false);

          // Redirect to home
          router.push('/');

          Store.addNotification({
            title: 'Uspješno ste se prijavili!',
            message: 'Dobrodošli.',
            type: 'success',
            insert: 'top',
            container: 'top-center',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              delay: 500,
              duration: 2000,
              onScreen: false,
            },
          });
        }
      })
      .catch(console.log);
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    setIsUpdating(true);
    getUser();
  };

  return (
    <form className="modal-box login-box">
      <CloseIcon fontSize="medium" className="modal-close-x" />
      <div className="title">Prijavi se</div>
      <div className="inputs">
        <div className="form-control">
          <input
            type="text"
            name="email"
            autoFocus
            placeholder="Email adresa"
            onChange={(e) => setEmail(e.target.value)}
            className={authMessage === 'No user' ? 'validation-error' : null}
          />
          <small>
            {authMessage.length > 0 && authMessage === 'No user'
              ? 'Traženi korisnik ne postoji!'
              : email.length > 0 && !checkEmail(email)
              ? 'Molimo unesite valjanu email adresu.'
              : authMessage.length > 0 && authMessage === 'Recaptcha fail'
              ? 'Recaptcha provjera nije uspijela.'
              : null}
          </small>
        </div>
        <div className="form-control">
          <input
            type="password"
            name="password"
            placeholder="Lozinka"
            onChange={(e) => setPassword(e.target.value)}
            className={authMessage === 'Bad password' ? 'validation-error' : null}
          />
          <small>
            {password.length > 0 && password.length < 6
              ? 'Unesite minimalno 6 znakova.'
              : password.length > 0 && authMessage === 'Bad password'
              ? 'Unesena je pogrešna lozinka.'
              : null}
          </small>
        </div>
      </div>
      <div className="buttons">
        <button
          type="submit"
          disabled={!allowSignIn || isUpdating}
          className="button button-lokalac"
          onClick={handleSignIn}
        >
          {isUpdating ? (
            <AvTimerIcon />
          ) : isUpdating === 200 ? (
            <DoneAllIcon />
          ) : isUpdating === 400 ? (
            <ErrorOutlineIcon />
          ) : (
            'Prijavi se'
          )}
        </button>
        <div className="or">
          <div className="line"></div>
          <div>ili</div>
          <div className="line"></div>
        </div>
        <div onClick={() => signIn('google')} className="button button-google">
          Prijavi se Gmailom
        </div>
        <div onClick={() => signIn('facebook')} className="button button-facebook">
          Prijavi se Facebookom
        </div>
        {authMessage === 'Bad password' ? (
          <div className="forgot-password" onClick={() => setUserModalType('forgotPwd')}>
            Zaboravili ste lozinku?
          </div>
        ) : null}
        <div className="new-account" onClick={() => setUserModalType('signUp')}>
          Nemate račun? Registrirajte se ovdje!
        </div>
      </div>
    </form>
  );
};

export default UserLogin;
