import { useState, useEffect, useContext } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import dynamic from 'next/dynamic';
import debounce from 'lodash.debounce';

import { useCookies } from 'react-cookie';
import { Store } from 'react-notifications-component';

import { UserContext } from '@/context/user.context';
import { FeedsContext } from '@/context/feeds.context';
import { UserModalContext } from '@/context/user-modal.context';

import { Badge } from '@mantine/core';

import {
  Container,
  UpperHeader,
  LogoContainer,
  LogoLink,
  Logo,
  RightSide,
  SelectOptions,
  Option,
  UserArea,
  SignIn,
  SignOut,
  Preferences,
  LowerHeader,
  Categories,
  Category,
  LocationsContainer,
  SmallScreenIcons,
  OptionWrapper,
  SmallScreenUserArea,
  UserIconWrapper,
  UserDropBar,
  CategoriesMenu,
  CategoriesMenuItems,
  CategoryMenuItem,
  LoginAnimationContainer,
  RssContainer,
  AdminIcon,
} from './header.elements';

import { categories } from '@/data/categories.data';

import { logoWhite, logoTransparent } from '@/assets';

import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WidgetsTwoTone from '@material-ui/icons/WidgetsTwoTone';

// Dynamic imports
const UserModal = dynamic(() => import('../user-modal/user-modal.component'));
const CollapsibleWrapper = dynamic(() =>
  import('../collapsible-wrapper/collapsible-wrapper.component')
);
const LocationFilter = dynamic(() => import('../location-filter/location-filter.component'));

const Header = ({ light }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [showLocationFilter, setShowLocationFilter] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showCategoriesFilter, setShowCategoriesFilter] = useState(false);
  const [view, setView] = useState('categories');
  const { user, setUser } = useContext(UserContext);
  const { feeds } = useContext(FeedsContext);
  const { showUserModal, setShowUserModal, setUserModalType } = useContext(UserModalContext);
  const [cookies, setCookie, removeCookie] = useCookies();
  const router = useRouter();

  const returnUserCategories = () => {
    setCookie('categories', user.preferences.categories, { path: '/' });
    router.push('/');
  };

  const handleCategoryClick = debounce((category) => {
    setShowCategoriesFilter(false);

    if (user.isAuthenticated) {
      if (category !== 'logo') {
        setCookie(
          'categories',
          category === 'sve'
            ? categories.map((category) => category.name)
            : [categories.filter((cat) => cat.slug === category)[0].name],
          { path: '/' }
        );
      } else {
        returnUserCategories();
      }
    } else {
      setCookie(
        'categories',
        category === 'sve' || category === 'logo'
          ? categories.map((category) => category.name)
          : [categories.filter((cat) => cat.slug === category)[0].name],
        { path: '/' }
      );
    }

    router.push(`${category === 'sve' || category === 'logo' ? '/' : `/${category}`}`);
  }, 250);

  const hideLocationsGoToCategories = () => {
    setShowLocationFilter(false);
    setShowCategoriesFilter(false);
    setTimeout(() => setView('categories'), 600);
  };

  const toggleDropdowns = (target) => {
    if (target === 'locations') {
      setShowLocationFilter(!showLocationFilter);
      setShowCategoriesFilter(false);
    } else if (target === 'categories') {
      setShowCategoriesFilter(!showCategoriesFilter);
      setShowLocationFilter(false);
    }
  };

  const handleSignOut = () => {
    const token = window.localStorage.getItem('token');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', authorization: token },
      body: JSON.stringify({ userId: user.userId }),
    };

    signOut({ redirect: false });

    setUser({ ready: true, isAuthenticated: false });
    window.localStorage.removeItem('token');

    setCookie('feeds', feeds.feeds, { path: '/' });
    setCookie(
      'categories',
      categories.map((category) => category.name),
      { path: '/' }
    );
    removeCookie('userLoggedIn');

    fetch('/api/v1/user/signOut', requestOptions)
      .then((res) => res.json())
      .catch(console.log);

    Store.addNotification({
      title: 'Uspješno ste se odjavili!',
      message: 'Vidimo se opet.',
      type: 'default',
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        delay: 500,
        duration: 2000,
        onScreen: false,
      },
    });

    setShowUserMenu(false);

    router.push('/');
  };

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    setVisible(
      (prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) ||
        currentScrollPos < 10
    );

    if (!visible) {
      hideLocationsGoToCategories();
      setShowUserMenu(false);
    }

    setPrevScrollPos(currentScrollPos);
  }, 100);

  useEffect(() => {
    setShowLocationFilter(false);
    setShowCategoriesFilter(false);
  }, [router.pathname]);

  /* On 'Escape' keypress, close locations filter  */
  useEffect(() => {
    window.addEventListener('keydown', (e) =>
      e.key === 'Escape' ? hideLocationsGoToCategories() : null
    );
    return () => window.removeEventListener('keydown', null);
  });

  /* Control header slide on scroll up and down */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  useEffect(() => {
    if (window.scrollY === 0) setVisible(true);
  });

  useEffect(() => {
    if (user.isAuthenticated)
      if (window.innerWidth > 769)
        setTimeout(() => {
          setIsAuth(user.isAuthenticated);
        }, 2800);
      else
        setTimeout(() => {
          setIsAuth(user.isAuthenticated);
        }, 1650);
    else setIsAuth(user.isAuthenticated);
  }, [user.isAuthenticated]);

  return (
    <Container $isVisible={visible}>
      <UpperHeader $isAuthenticated={isAuth}>
        <LoginAnimationContainer $isAuthenticated={user.isAuthenticated} />
        <LogoContainer>
          <LogoLink onClick={() => handleCategoryClick('logo')}>
            {isAuth ? (
              <Logo $isAuthenticated={isAuth} src={logoWhite} alt="Lokalac" />
            ) : (
              <Logo $isAuthenticated={isAuth} src={logoTransparent} alt="Lokalac" />
            )}
          </LogoLink>
        </LogoContainer>
        <RightSide $isAuthenticated={isAuth}>
          {user.ready && view ? (
            <SelectOptions $light={light}>
              {user.ready && !isAuth ? (
                <Option
                  $isAuthenticated={isAuth}
                  $isSelected={view === 'locations'}
                  onClick={() => {
                    setView('locations');
                    setShowLocationFilter(true);
                  }}
                >
                  Odaberi gradove/županije
                </Option>
              ) : null}
              <Option
                $isAuthenticated={isAuth}
                $isSelected={view === 'categories'}
                onClick={hideLocationsGoToCategories}
              >
                Odaberi kategoriju
              </Option>
              {user.isAuthenticated &&
              user.preferences.categories.sort().toString() !=
                cookies?.categories.sort().toString() ? (
                <Option $isAuthenticated={isAuth} onClick={returnUserCategories}>
                  Vrati moje kategorije
                </Option>
              ) : null}
            </SelectOptions>
          ) : null}
          {/* <RssContainer href={'https://lokalac.hr/rss.xml'} target="_blank" $light={light}>
            <RssIcon fontSize="medium" />
          </RssContainer> */}
          {user.ready ? (
            <UserArea $isAuthenticated={isAuth}>
              {!isAuth ? (
                <SignIn
                  onClick={() => {
                    hideLocationsGoToCategories();
                    setShowUserModal(true);
                    setUserModalType('signIn');
                  }}
                >
                  Prijavi se
                </SignIn>
              ) : (
                <>
                  {user?.isAdmin ? (
                    <AdminIcon $light={light}>
                      <Link href="/admin">
                        <WidgetsTwoTone />
                      </Link>
                    </AdminIcon>
                  ) : null}
                  <Preferences $light={light}>
                    <Link href="/personaliziraj">Izmjeni preference</Link>
                  </Preferences>
                  <SignOut onClick={handleSignOut}>Odjavi se</SignOut>
                </>
              )}
            </UserArea>
          ) : null}

          {user.ready && isAuth ? (
            <SmallScreenUserArea>
              <UserIconWrapper>
                <MenuRoundedIcon fontSize="medium" onClick={() => setShowUserMenu(!showUserMenu)} />
              </UserIconWrapper>
              {showUserMenu ? (
                <UserDropBar>
                  {user?.isAdmin ? (
                    <Preferences>
                      <Link href="/admin">Administracija</Link>
                    </Preferences>
                  ) : null}
                  <Preferences>
                    <Link href="/personaliziraj">Izmjeni preference</Link>
                  </Preferences>
                  <SignOut onClick={handleSignOut}>Odjavi se</SignOut>
                </UserDropBar>
              ) : null}
            </SmallScreenUserArea>
          ) : null}
        </RightSide>
      </UpperHeader>

      <LowerHeader $light={light}>
        {view === 'categories' ? (
          <Categories>
            {categories.map((category, i) => (
              <Badge
                variant={
                  router.query.category &&
                  router.query.category.length > 0 &&
                  router.query.category[0] === `${category.slug}`
                    ? ''
                    : 'transparent'
                }
                size="lg"
                p={`calc(1rem / 1.5)`}
                radius="xl"
                key={i}
                onClick={() => handleCategoryClick(category.slug)}
                sx={{
                  cursor: 'pointer',
                  border:
                    router.query.category &&
                    router.query.category.length > 0 &&
                    router.query.category[0] === `${category.slug}`
                      ? `1px solid var(--type-col-${category.slug})`
                      : null,

                  background:
                    router.query.category &&
                    router.query.category.length > 0 &&
                    router.query.category[0] === `${category.slug}`
                      ? `var(--type-col-${category.slug})`
                      : null,
                }}
              >
                {category.name}
              </Badge>
            ))}
          </Categories>
        ) : null}
        <LocationsContainer>
          <CollapsibleWrapper
            open={showLocationFilter}
            className="Collapsible-filter"
            openedClassName="Collapsible-filter"
          >
            <LocationFilter setShowLocationFilter={setShowLocationFilter} setView={setView} />
          </CollapsibleWrapper>
        </LocationsContainer>
        <CategoriesMenu>
          <CollapsibleWrapper
            open={showCategoriesFilter}
            className="Collapsible-categories"
            openedClassName="Collapsible-categories"
          >
            <CategoriesMenuItems>
              {categories.map((category, i) => (
                <Badge
                  key={i}
                  onClick={() => handleCategoryClick(category.slug)}
                  variant={
                    router.query.category &&
                    router.query.category.length > 0 &&
                    router.query.category[0] === `${category.slug}`
                      ? ''
                      : 'transparent'
                  }
                  size="lg"
                  radius="xl"
                  sx={{
                    cursor: 'pointer',

                    border:
                      router.query.category &&
                      router.query.category.length > 0 &&
                      router.query.category[0] === `${category.slug}`
                        ? `1px solid var(--type-col-${category.slug})`
                        : null,

                    background:
                      router.query.category &&
                      router.query.category.length > 0 &&
                      router.query.category[0] === `${category.slug}`
                        ? `var(--type-col-${category.slug})`
                        : null,
                  }}
                >
                  {category.name}
                </Badge>
              ))}
            </CategoriesMenuItems>
          </CollapsibleWrapper>
        </CategoriesMenu>
        <SmallScreenIcons>
          {user.ready && !user.isAuthenticated ? (
            <OptionWrapper onClick={() => toggleDropdowns('locations')}>
              Gradovi i županije
              {showLocationFilter ? (
                <ExpandLessIcon fontSize="medium" />
              ) : (
                <ExpandMoreIcon fontSize="medium" />
              )}
            </OptionWrapper>
          ) : cookies &&
            cookies?.preferences &&
            user?.preferences?.categories.sort().toString() !=
              cookies?.categories.sort().toString() ? (
            <OptionWrapper onClick={returnUserCategories}>Vrati moje kategorije</OptionWrapper>
          ) : (
            <OptionWrapper />
          )}
          <OptionWrapper onClick={() => toggleDropdowns('categories')}>
            Kategorije
            {showCategoriesFilter ? (
              <ExpandLessIcon fontSize="medium" />
            ) : (
              <ExpandMoreIcon fontSize="medium" />
            )}
          </OptionWrapper>
        </SmallScreenIcons>
      </LowerHeader>
      <UserModal show={showUserModal} />
    </Container>
  );
};

export default Header;
