import { useState, useEffect } from 'react';

import { Radio as Loader } from 'react-loader-spinner';

import { Container } from './no-data.elements';

const NoData = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 5303);
  }, []);

  return loading ? (
    <Container>
      <Loader colors={['#0c67a0', '#0f7ec5', '#0f7ec5']} height={100} width={100} />
    </Container>
  ) : (
    <Container>Nema rezultata za tražene parametre.</Container>
  );
};

export default NoData;
