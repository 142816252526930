import styled from 'styled-components';

export const Container = styled.div`
  max-width: 768px;
  margin: 0 auto;
  text-align: justify;

  h1 {
    margin-bottom: 2rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  p > a {
    color: var(--lokalac-primary-2);
  }
`;
